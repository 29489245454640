<template>
  <div class="header_wrap">
    <Alert v-if="!getIsMainChainID" :message="$t('l.notmainnet_title')" :description="$t('l.notmainnet_tips')" type="warning" show-icon closable :after-close="handleClose"/>
    <div class="header_inner_wrap">
      <div class="left_nav">
        <div class="logo_wrap">
          <div @click="isShowDrawer = true" alt="" class="mobile_nav_icon"></div>
          <img src="../assets/logo.svg" alt="" class="logo">
        </div>
        <ul class="nav_text pc_nav_text">
          <li class="nav_item" :class="currentIndex == item.index ? 'clickedNav': ''" v-for="(item,index) in navArr" :key="index"><a @click="handleJump(item.path,item.index)">{{$t('l.h_n'+item.index)}}</a>
            <div class="nav_line"></div>
          </li>
        </ul>
      </div>
      <div class="right_nav">
        <a @touchstart="handleTapStart" @touchend="handleTapEnd" class="c_btn" v-if="!isUserConnected" @click="handleConnectWeb3Modal"><span class="c_btn_text">{{$t('l.cwallet')}}</span></a>
        <a class="h_address" v-if="isUserConnected" @click="disconnectWeb3Modal">{{formatAddress(getActiveAccount)}}</a>
        <a class="lang_change pc_lang_change" @click="showLangBox" :class="this.$i18n.locale == 'zh-CN' ? 'en-US' : 'zh-CN'"><i class="icon"></i>{{this.lanc}}
          <div id="languageBox" v-show="languageShow">
            <li :class="this.$i18n.locale == 'en-US' ? 'active' : ''" @click.stop.prevent="changeLangType(1)">English</li>
            <li :class="this.$i18n.locale == 'zh-CN' ? 'active' : ''" @click.stop.prevent="changeLangType(2)">简体中文</li>
          </div>
        </a>
      </div>
    </div>
    <Drawer class="header_drawer" placement="left" :closable="true" :visible="isShowDrawer" @close="isShowDrawer = false" width="100%">
      <div class="drawer_wrap">
        <ul class="nav_text mobile_nav_text">
          <li class="nav_item" :class="currentIndex == item.index ? 'clickedNav': ''" v-for="(item,index) in navArr" :key="index">
            <!-- <Icon class="iconLists" :type="item.icon"/> -->
            <a @click="handleJump(item.path,item.index)">{{$t('l.h_n'+item.index)}}</a>
          </li>
        </ul>
        <a class="lang_change lang_change_mobile" @click.stop.prevent="changeLangType($i18n.locale == 'zh-CN' ? 1 : 2)"><i :class="'lanIcon lanIcon'+($i18n.locale == 'zh-CN' ? 1 : 2)"></i>{{lanc}}</a>
      </div>
    </Drawer>
  </div>
</template>

<script>
import { Alert, Drawer } from 'ant-design-vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    Alert,
    Drawer,
  },
  data() {
    return {
      currentIndex: 3,
      languageShow: false,
      lanc: '',
      currentHost: '',
      isShowDrawer: false,
      showAlertTips: false,
      navArr: [
        { index: 1, path: 'nftmining', icon: 'bank' },
        { index: 2, path: 'mynft', icon: 'bank' },
        { index: 3, path: 'market', icon: 'bank' },
        { index: 4, path: 'synthesis', icon: 'bank' },
        { index: 5, path: 'vault', icon: 'bank' },
        //{ index: 6, path: 'battle', icon: 'bank' },
        { index: 7, path: 'doystore', icon: 'bank' },
        { index: 8, path: 'doydeposit', icon: 'bank' },
      ],
    }
  },
  methods: {
    ...mapActions('accounts', ["initWeb3Modal", "connectWeb3Modal", "disconnectWeb3Modal"]),
    switchMenu() {
      this.$store.dispatch('accounts/changeMenuStatus')
    },
    handleClose() {
      this.showAlertTips = false
    },
    showLangBox() {
      this.languageShow = !this.languageShow;
    },
    setLan() {
      if (this.getLangType == 'zh-CN') {
        this.lanc = "简体中文";
      } else if (this.getLangType == 'en-US') {
        this.lanc = "English";
      }
    },
    setCookie(key, value) {
      var oDate = new Date();
      oDate.setDate(oDate.getDate() + 36000);
      document.cookie = key + "=" + value + "; expires=" + oDate.toDateString();
    },
    changeLangType(type) {
      var llWYf = '';
      if (type == 1) {
        this.$i18n.locale = 'en-US';
        this.lanc = "English";
        llWYf = 'en';
      } else if (type == 2) {
        this.$i18n.locale = 'zh-CN';
        this.lanc = "简体中文";
        llWYf = 'zh-CN';
      }
      this.languageShow = false
      this.$store.commit('accounts/setLangType', this.$i18n.locale)
      localStorage.setItem('langType', this.$i18n.locale);
      this.setCookie('pipipSwapLanguage', llWYf);
    },
    handleTapStart(e) {
      e.target.classList.toggle('tap')
    },
    handleTapEnd(e) {
      e.target.classList.toggle('tap')
    },
    async handleConnectWeb3Modal() {
      let result = await this.connectWeb3Modal()
      if (result && result.status == 400) {
        this.$message.warning(this.$t('l.no_metamask_tips'))
      }
    },
    formatAddress(address) {
      if (address !== '' && address !== undefined) {
        let pre = address.slice(0, 6)
        let suf = address.slice(-4)
        return `${pre}...${suf}`
      } else {
        return ''
      }
    },
    getIndex() {
      let str = window.location.href;
      if (str.indexOf("nftmining") != -1) {
        this.currentIndex = 1
      }else if (str.indexOf("mynft") != -1) {
        this.currentIndex = 2
      }else if (str.indexOf("market") != -1) {
        this.currentIndex = 3
      }else if (str.indexOf("synthesis") != -1) {
        this.currentIndex = 4
      }else if (str.indexOf("vault") != -1) {
        this.currentIndex = 5
      }else if (str.indexOf("battle") != -1) {
        this.currentIndex = 6
      }else if (str.indexOf("doystore") != -1) {
        this.currentIndex = 7
      }else if (str.indexOf("doydeposit") != -1) {
        this.currentIndex = 8
      } else {
        this.currentIndex = 1
      }
    },
    handleJump(path, index) {
      this.isShowDrawer = false
      this.currentIndex = index;
      this.$router.push({ path: '/' + path })
      this.getIndex();
    }
  },
  computed: {
    ...mapGetters('accounts', ["getActiveAccount", "isUserConnected", "getWeb3Modal", 'getIsMainChainID', 'getLangType']),
  },
  mounted() {
    this.currentHost = location.host
    this.getIndex();
    this.setLan();
  },
  created() {
    this.$store.dispatch('accounts/initWeb3Modal');
    this.$store.dispatch('accounts/ethereumListener');
  },
  watch: {
    '$route'(to) {
      this.currentIndex = to.meta.index
      this.getIndex()
    }
  }
}
</script>

<style scoped>
.dashedLine {
  width: 100vw;
  border-bottom: 2px dashed #359a6c;
  position: absolute;
  left: 0;
  top: 65px;
}
#languageBox {
  width: 100%;
  position: absolute;
  top: 24px;left: 0;
  margin-top: 12px;
  padding: 20px 0;
  background-color: white;
  border-radius: 10px;
}
#languageBox li {
  list-style: none;
  color: black;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
#languageBox li.active {
  color: #52CCC5;
}
#languageBox li:firstk-child {
  margin-bottom: 24px;
}
.nav_text .clickedNav a {
  color: #52CCC5;
}
.nav_text .clickedNav .iconLists {
  color: #52CCC5;
}
.nav_line {
  width: 100%;
  height: 3px;
  background-color: #52CCC5;
  position: absolute;
  bottom: -10px;
  display: none;
}
.clickedNav .nav_line {
  display: block;
}
.icon6 {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
  border-radius: 4px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../assets/exIco.svg);
  position: absolute;
  top: 8px;
  right: 10px;
}
.header_wrap {
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 90;
  height: 56px;
}
.header_inner_wrap {
  width: 100%;
  margin: 0 auto;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 8px 16px;
}
.left_nav {
  display: flex;
  flex-wrap: nowrap;
  margin-right: auto;
  align-items: center;
}
.nav_text {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
}
.nav_text .nav_item {
  margin-right: 24px;
  position: relative;
  white-space: nowrap;
}
.nav_text a {
  color: #fff;
}
.nav_item .iconLists {
  color: #fff;
  margin-right: 8px;
}
.header_wrap .c_btn {
  height: 32px;
  padding: 4px 12px;
  background-color: transparent;
  font-size: 16px;
  color: #52CCC5;
  border-radius: 16px;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.c_btn_text {
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 14px;
  border-radius: 20px;
  padding: 0 22px;
}
.right_nav {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: auto;
  flex-direction: row;
}
.right_nav .c_btn {
  border: 2px solid #52CCC5;
  float: right;
  margin-right: 15px;
}
.h_address {
  float: right;
  color: #20c7d3;
  font-size: 16px;
  border: 2px solid #52CCC5;
  color: #52CCC5;
  border-radius: 100px;
  height: 32px;
  padding: 4px 12px;
  position: relative;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.menu_icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin: 0 0 0 16px;
  cursor: pointer;
  display: none;
  background-image: url('../assets/m_menu_icon.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.m_logo {
  display: none;
  width: 40px;
  height: 40px;
  margin: 0 0 0 24px;
  vertical-align: middle;
}
.logo_wrap {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-right: 42px;
  width: 37px;
  height: 37px;
}
.logo_wrap .mobile_nav_icon {
  width: 44px;
  height: 44px;
  margin-right: 16px;
  display: none;
  float: left;
  background-image: url('../assets/mobilButton.svg');
}
.logo_wrap img {
  width: 40px;
  height: 36px;
}
.lang_change {
  height: 32px;
  width: 100px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #52CCC5;
  font-size: 14px;
  border: 2px solid #52CCC5;
  position: relative;
}
.lang_change .icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin: 0 16px 0 24px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: none !important;
}
.en-US .icon {
  background-image: url('../assets/mg.svg');
}
.zh-CN .icon {
  background-image: url('../assets/zg.svg');
}
.drawer_wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.pc_nav_text {
  display: flex;
}
@media (max-width: 768px) {
  .logo_wrap {
    width: 120px;
    height: 37px;
    margin-right: 14px;
  }
  .h_address {
    float: left;
    max-width: 200px;
    margin: 0;
  }
  .header_wrap .c_btn {
    margin: 0 0 0 auto;
    font-size: 14px;
    float: left;
  }
  .header_inner_wrap {
    height: 100%;
    padding-left: 0;
    display: flex;
    align-items: center;
    margin-top: 0;
  }
  .dashedLine {
    top: 55px;
  }
  .right_nav {
    margin-left: auto;
    display: block;
  }
  .menu_icon {
    display: inline-block;
  }
  .m_logo {
    display: inline-block;
  }
  .lang_change .icon {
    margin: 0 16px 0 0;
  }
  .pc_nav_text {
    display: none;
  }
  .mobile_nav_text {
    display: flex;
    flex-direction: column;
  }
  .mobile_nav_text .nav_item {
    height: 52px;
    line-height: 52px;
    border-bottom: 1px solid #222c37;
  }

  .lang_change_mobile {
    position: absolute;
    bottom: 24px;
  }

  .logo_wrap .mobile_nav_icon {
    display: inline-block;
  }
  .pc_lang_change {
    display: none;
  }
}
</style>
<style>
.ant-alert {
  width: 100%;
  max-width: 100vw;
  position: fixed!important;
}
ul {
  margin-bottom: 0 !important;
}
.header_drawer .ant-drawer-content {
  background-color: #000;
}
.header_drawer .anticon-close {
  color: #fff;
}
.ant-alert-warning {
  top: 0;
  z-index: 9999;
  background-color: rgba(197,159,82,.8)!important;
}
</style>