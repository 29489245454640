var token1 = 'WFC';
var token2 = "DOP";
var token3 = "USDT";
var token4 = "DOY";


export const l = {
  token1,
  token2,
  token3,
  token4,
  h_n1 : "NFT 盲盒",
  h_n2 : "我的NFT",
  h_n3 : "市场",
  h_n4 : "NFT 合成",
  h_n5 : "NFT 挖矿",
  h_n6 : "NFT 对战",
  h_n7 : "DOY 交易",
  h_n8:  "DOY 挖矿",


  cwallet: "连接钱包",
  left_box: "剩余盲盒",
  ge: "个",
  balance: "余额",
  value: "价值",
  buy: "购买",
  confirm: "确定",
  my_nft: "我的NFT >>",
  box_title: "盲盒说明",
  box_desc: "每个盲盒需要价值50U的WFC+200U的DOP抽取，有五种不同的NFT。",
  bag: "背包中",
  sell: "挂卖中",
  diamond: "钻石",
  invite_link: "邀请链接",
  num: "编号",
  status: "状态",
  price: "售价(USDT)",
  sale: "挂卖",
  unsale: "取消挂卖",
  update: "升级",
  cancel: "取消",
  left_diamond: "剩余钻石",
  update_cost: "升级钻石",
  normal: "普通NFT",
  super: "超级NFT",
  cost_ava: "消耗价值",
  hc: "合成超级NFT",
  hc_title: "合成说明",
  hc_desc: "合成 1 个超级NFT需要 5 个不同的普通NFT，<br />合成 1 个超级NFT需要消耗50U的WFC",
  ava_token: "收获钻石",
  rcl: "日产量",
  y_lock: "你的锁仓",
  t_lock: "总锁仓",
  deposit: "质押",
  withdraw: "提取",
  mining_title: "挖矿说明",
  mining_desc: "超级卡牌 + 等同卡牌等级数量的DOP 进行挖矿，挖矿产出的“升级钻石DOY”，不同等级的卡牌挖矿效率不同，挖矿效率等价于卡牌整体花费的费用，提取时项目方抽取所获取的钻石等价10%手续费（WFC），被邀请的可以获得5%的挖矿收益；升级钻石可去<a href='https://www.wfcdefi.com/swap/#/swap' target='_blank'>https://www.wfcdefi.com/swap/#/swap</a>上用USDT交易",
  left: "剩余",
  level: "等级",
  need: "所需",
  operate: "操作",
  d_d_output: "钻石日产量",
  harvest: "收益",
  claim: "提取",
  claim_sy: "提取收益",
  deposit_dop: "存入DOP",
  draw: "提取",
  battle: "战斗",
  create: "创房",
  battle_record: "战斗记录",
  create_battle: "创建房间",
  battle_amount: "对战金额(USDT)",
  fight: "开战",
  evalute: "结算",
  cancel_battle: "取消创建",
  choose_nft: "选择NFT",
  copy_success: "复制成功！",
  copy_error: "复制失败,请重试！",
  go: "跳转",
  prev: "上一页",
  next: "下一页",
  approve: "授权",
  operate_ok: "操作成功！",
  price_error: "请输入正确的价格！",
  enterNum: "请输入编号",
  choose_normal: "选择普通NFT",
  no_nft: "您还没有NFT，请先去购买",
  no_own: "您还没有此编号的NFT，请先去购买！",
  number_error: "请输入正确的编号！",
  transSameAddress: '您已经拥有此NFT',
  choose_more_than: "您已经选够5个待合成NFT！",
  not_enough_poke: "您当前不够5个类型的NFT",
  choosed_not_enough_poke: "请选择5个NFT",
  choosed_same: "您已经选择了此类型的NFT",
  deposit_all_title: "NFT数量",
  deposit_all: "全部质押",
  withdraw_all: "全部提取",
  error_tips_claim: "您还没有可领取的奖励！",
  ok_tips_claim: "领取奖励成功！",
  ok_tips_deposit: "存入成功！",
  ok_tips_withdraw: "提取成功！",
  ok_tips_claim_exit: "退出：领取奖励 & 解押",
  mint_done_tips: "NFT已经全部抽取完毕！",
  not_enough_wfc: "合成所需的"+token1+"不足",
  f_title_1: "序号",
  f_title_2: "等级",
  f_title_3: "钻石",
  f_title_4: "操作",
  fight_info_title: "战斗说明",
  fight_info_desc: "英雄PK时可以下赌注，"+token4+" 支付，PK获胜方 10%给平台，另外 5%给推荐人。",
  createbattle1: "创建房间",
  add_nft_text: "选择NFT",
  battle_amount_ipt: "请输入战斗金额(最少 88 "+token4+")",
  tips_title: "手续费",
  battle_amount_error: "请输入正确的对战金额",
  evaluated: "结算",
  cancel_game: "取消创建",
  monbattle: "开始战斗",
  player1_monster: "对方NFT",
  player2_monster: "我方NFT",
  start_battle: "开始",
  battle_amount_modal: "战斗金额",
  not_enough_balance: "余额不足",
  record_title: "战斗记录",
  battle_modal_amount: "金额("+token4+")",
  game_num: "序号",
  battle_result: "战斗结果",
  win_text: "胜利",
  draw_text: "平局",
  lose_text: "失败",
  doy_buy: "购买",
  doy_sale: "出售",
  doy_buy_nav: "挂买",
  doy_sale_nav: "挂卖",
  doy_nav_type1_market: "挂卖市场",
  doy_nav_type1_my: "我的挂卖",
  doy_nav_type2_market: "挂买市场",
  doy_nav_type2_my: "我的挂买",
  sale_doy_btn: "挂卖钻石",
  buy_doy_btn: "挂买钻石",
  doy_title_1: "数量",
  doy_title_2: "单价("+token3+")",
  doy_title_3: "价格("+token3+")",
  doy_title_4: "操作",
  cancel_doy_sale: "取消挂卖",
  cancel_doy_buy: "取消挂买",
  doy_price_title: token4+"最近成交单价",
  doy_sale_info_item_title1: "钻石余量",
  doy_sale_info_item_title2: "出售数量",
  doy_buy_info_item_title2: "购买数量",
  doy_sale_info_item_title3: "价格",
  doy_ipt_place1: "请输入数量",
  doy_ipt_place2: "请输入单价",
  error_doy_input: "请输入正确的钻石数量和单价",
  error_doy_input2: "请输入正确的钻石数量",
  insufficient_doy_input: "钻石余额不足",
  insufficient_usdt_input: "USDT余额不足",
  reward: "收获",
  iptPlace: "输入数量",
  error_tips_num0: "请输入大于0的数字",
  not_enough_reward: "暂无可提取的收益",
  not_enough_deposit: "暂无可提取的"+token4,
  more_than_deposit: "输入的提取数量大于质押总量",
  need_to_pay: "所需费用",
  receive: "收到",
  trans_all: "全部",

  footer1 : '审计',
  sjurl: 'https://wfcbsc.com/depfilm/audit.pdf',
  footer2 : '推特',
  footer3 : '电报',

  langtype: "English",
  approve_ok: "授权成功，请继续操作！",
  error_tips_unconnect: "请打开MetaMask钱包，并连接您的账户！",
  notmainnet_title: "警告",
  notmainnet_tips: "您当前浏览的数据不是来自于主网络，请切换您的钱包到主网络！",
  no_main_tips: "网络错误，请确认您连接的是 BSC mainnet!",
  no_metamask_tips: "您还没有安装MetaMask钱包插件！",
  catch_err: "网络异常",
}