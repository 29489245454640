<template>
  <div class="vault_wrap">
    <Spin class="global_loading" tip="loading" :spinning="spinStatus" size="large">
      <div class="vault_inner_wrap">
        <ul class="pool_wrap">
          <li class="pools_item" v-for="(item,index) in poolsLists" :key="index">
            <Spin tips="loading" :spinning="item.isLoading" size="large">
              <div class="pools_item_wrap">
                <div class="info_1">
                  <img :src="require('../assets/'+item.logo_url)" alt="">
                  <span class="pool_title">{{item.tokenName}} {{$t('l.token2')}}</span>
                  <span class="ava_token">{{$t('l.ava_token')}}</span>
                </div>
                <div class="info_2 info_2_line1">
                  <span class="title">{{$t('l.rcl')}}</span>
                  <span class="val"><countTo :endVal='item.rcl/1' :duration='3000' :decimals="6"></countTo></span>
                </div>
                <div class="info_2">
                  <span class="title">{{$t('l.y_lock')}}</span>
                  <span class="val"><countTo :endVal='item.deposited/1' :duration='3000' :decimals="0"></countTo></span>
                </div>
                <div class="info_2">
                  <span class="title">{{$t('l.t_lock')}}</span>
                  <span class="val"><countTo :endVal='item.lock/1' :duration='3000' :decimals="0"></countTo></span>
                </div>
                <div class="operate_wrap">
                  <div class="edit_btn ghost_btn withdraw_btn" @click="handleShowWithDrawModal(item.index)">{{$t('l.withdraw')}}</div>
                  <div class="edit_btn deposit_btn" v-if="item.isApproved" @click="handleShowDepositModal(item.index)">{{$t('l.deposit')}}</div>
                  <div class="edit_btn deposit_btn" v-else @click="handleApprovedFor(item.index)">{{$t('l.approve')}}</div>
                </div>
              </div>
            </Spin>
          </li>
        </ul>
        <div class="mining_wrap">
          <p class="mining_title">{{$t('l.mining_title')}}</p>
          <p class="mining_desc" v-html="$t('l.mining_desc')"></p>
        </div>
      </div>
      <Modal class="deposit_modal" v-model="isShowDepositModal" :footer="null" :width="!$store.state.accounts.isMobile ? '500px' : '95%'" height="90%" @cancel="handleDepositModalCancel" :centered="true" :destroyOnClose="true" :dialog-style="{ top: '30px' }">
        <div class="deposit_modal_wrap">
          <p class="modal_title">{{$t('l.deposit')}}</p>
          <div class="info_1 modal_info1">
            <img src="../assets/logo.svg" alt="">
            <span class="pool_title">{{currentPoolIndex > -1 ? poolsLists[currentPoolIndex].tokenName : ''}}{{$t('l.token2')}}</span>
            <span class="ava_token">{{$t('l.ava_token')}}</span>
          </div>
          <div class="info_2">
            <span class="title">{{$t('l.y_lock')}}</span>
            <span class="val"><countTo :endVal='(currentPoolIndex > -1 ? poolsLists[currentPoolIndex].deposited : 0)/1' :duration='3000' :decimals="0"></countTo></span>
          </div>
          <div class="info_2">
            <span class="title">{{$t('l.left')}}{{$t('l.token2')}}</span>
            <span class="val"><countTo :endVal='dopBalance/1' :duration='3000' :decimals="0"></countTo></span>
          </div>
          <div class="deposit_all_wrap" v-if="myUndepositedNFTLists.length > 0">
            <p class="deposit_all_title">{{$t('l.deposit_all_title')}}</p>
            <div class="deposit_all_info">
              <span class="num">{{myUndepositedNFTLists.length}}</span>
              <div class="edit_btn deposit_all_btn" v-if="hasApprovedDop" @click="handleDepositConfirmAll">{{$t('l.deposit_all')}}</div>
              <div class="edit_btn deposit_all_btn" v-else @click="handleApprovedDop">{{$t('l.approve')}}</div>
            </div>
          </div>
          <ul class="table_wrap_title" v-if="myUndepositedNFTLists.length >= 1">
            <li class="table_title_item">{{$t('l.level')}}</li>
            <li class="table_title_item">{{$t('l.num')}}</li>
            <li class="table_title_item">{{$t('l.need')}}{{$t('l.token2')}}</li>
            <li class="table_title_item">{{$t('l.operate')}}</li>
          </ul>
          <ul class="table_wrap">
            <li class="table_item" v-for="(item,index) in myUndepositedNFTLists" :key="index">
              <span>lv{{item.level}}</span>
              <span>{{item.nftKey}}</span>
              <span>{{item.needDop}}</span>
              <span>
                <div class="edit_btn modal_btn" v-if="hasApprovedDop" @click="handleDepositConfirm([item.nftID])">{{$t('l.deposit')}}</div>
                <div class="edit_btn modal_btn" v-else @click="handleApprovedDop">{{$t('l.approve')}}</div>
              </span>
            </li>
          </ul>
        </div>
      </Modal>
      <Modal class="deposit_modal" v-model="isShowWithDrawModal" :footer="null" :width="!$store.state.accounts.isMobile ? '500px' : '95%'" height="90%" @cancel="handleWithDrawModalCancel" :centered="true" :destroyOnClose="true" :dialog-style="{ top: '30px' }">
        <div class="deposit_modal_wrap">
          <p class="modal_title">{{$t('l.withdraw')}}</p>
          <div class="info_1 modal_info1">
            <img src="../assets/logo.svg" alt="">
            <span class="pool_title">{{currentPoolIndex > -1 ? poolsLists[currentPoolIndex].tokenName : ''}}{{$t('l.token2')}}</span>
            <span class="ava_token">{{$t('l.ava_token')}}</span>
          </div>
          <div class="claim_wrap">
            <div class="claim_info_wrap">
              <span>{{$t('l.harvest')}}</span>
              <span><countTo :endVal='incomeAmount/1' :duration='3000' :decimals="6"></countTo>{{$t('l.diamond')}}</span>
            </div>
            <div class="claim_btn edit_btn" v-if="isAbleClaim" @click="handleClaim">{{$t('l.claim_sy')}}</div>
            <div class="claim_btn edit_btn" v-else @click="handleDepositDop">{{$t('l.deposit_dop')}}</div>
          </div>
          <div class="info_2">
            <span class="title">{{$t('l.y_lock')}}</span>
            <span class="val"><countTo :endVal='(currentPoolIndex > -1 ? poolsLists[currentPoolIndex].deposited : 0)/1' :duration='3000' :decimals="0"></countTo></span>
          </div>
          <div class="info_2">
            <span class="title">{{$t('l.d_d_output')}}</span>
            <span class="val"><countTo :endVal='(currentPoolIndex > -1 ? poolsLists[currentPoolIndex].rcl : 0)/1' :duration='3000' :decimals="6"></countTo></span>
          </div>
          <div class="deposit_all_wrap" v-if="myDepositedNFTLists.length">
            <p class="deposit_all_title">{{$t('l.deposit_all_title')}}</p>
            <div class="deposit_all_info">
              <span class="num">{{myDepositedNFTLists.length}}</span>
              <div class="edit_btn deposit_all_btn" @click="handleWithDrawConfirmAll">{{$t('l.withdraw_all')}}</div>
            </div>
          </div>
          <ul class="table_wrap_title" v-if="myDepositedNFTLists.length >= 1">
            <li class="table_title_item">{{$t('l.level')}}</li>
            <li class="table_title_item">{{$t('l.num')}}</li>
            <li class="table_title_item">{{$t('l.need')}}{{$t('l.token2')}}</li>
            <li class="table_title_item">{{$t('l.operate')}}</li>
          </ul>
          <ul class="table_wrap">
            <li class="table_item" v-for="(item,index) in myDepositedNFTLists" :key="index">
              <span>lv{{item.level}}</span>
              <span>{{item.nftKey}}</span>
              <span>{{item.needDop}}</span>
              <span><div class="edit_btn modal_btn" @click="handleWithDraw([item.nftID])">{{$t('l.draw')}}</div></span>
            </li>
          </ul>
        </div>
      </Modal>
    </Spin>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { mapGetters } from 'vuex'
import { Spin, Modal } from 'ant-design-vue'
import countTo from 'vue-count-to';
import Web3 from 'web3'
import { batch, contract } from '@pooltogether/etherplex'
export default {
  components: {
    Modal,
    Spin,
    countTo
  },
  data() {
    return {
      spinStatus: false,
      isShowDepositModal: false,
      isShowWithDrawModal: false,
      setStoreDataInterval: null,
      getDataInterVal: null,
      currentPoolIndex: -1,
      currentPoolID: -1,
      poolsLists: [],
      incomeAmount: 0,
      myDepositedNFTLists: [],
      myUndepositedNFTLists: [],
      dopBalance: 0,
      hasApprovedDop: false,
      isAbleClaim: false
    }
  },
  computed: {
    ...mapGetters('accounts', ['getActiveAccount', 'getProviderEthers', 'getDemicals', 'getPageDemicals', 'getStakePoolsDemical', 'getDataUpdateTime', 'getIsMainChainID','emptyAddress']),
    ...mapGetters('contracts', ['getMaxUint256', 'getStakingNFTAbi', 'getStakingNFTAddress', 'getLordshipAbi', 'getLordshipAddress', 'getPriceOracleAbi', 'getPriceOracleAddress','getDiamondAbi','getDiamondAddress','getDOPAbi','getDOPAddress'])
  },
  methods: {
    handleWithDrawModalCancel() {
      this.currentPoolIndex = -1;
      this.currentPoolID = -1
      this.incomeAmount = 0
      this.isShowWithDrawModal = false
    },
    handleDepositModalCancel() {
      this.currentPoolIndex = -1;
      this.currentPoolID = -1
      this.incomeAmount = 0
      this.isShowDepositModal = false
    },
    async handleShowWithDrawModal(index) {
      if (this.getActiveAccount) {
        this.spinStatus = true
        this.isShowWithDrawModal = true
        this.currentPoolIndex = index
        this.myDepositedNFTLists = []
        let signer = this.getProviderEthers.getSigner();
        let contractStake = new ethers.Contract(this.poolsLists[index].address, this.poolsLists[index].type == 1 ? this.getStakingNFTAbi : this.getStakingNFTAbi, signer);
        let incomeHex = await contractStake.getStakeTotalUnclaimed(this.getActiveAccount, 0)
        this.incomeAmount = ethers.utils.formatUnits(incomeHex, this.getDemicals)
        this.isAbleClaim = await contractStake.isClaimable(this.getActiveAccount)
        await this.getMyNFTLists(1)
        this.spinStatus = false
      } else {
        this.$message.error(this.$t('l.error_tips_unconnect'))
      }
    },
    async handleWithDrawConfirmAll() {
      let ids = []
      this.myDepositedNFTLists.forEach(val => {
        ids.push(val.nftID)
      })
      await this.handleWithDraw(ids)
    },
    async handleWithDraw(ids) {
      if (this.getIsMainChainID) {
        try {
          if (!this.getProviderEthers || !this.getActiveAccount) {
            this.$message.error(this.$t('l.error_tips_unconnect'))
            return
          }
          if (this.currentPoolIndex > -1 && ids.length > 0) {
            let signer = this.getProviderEthers.getSigner();
            let contractStake = ''
            if (this.poolsLists[this.currentPoolIndex].type == 1) {
              contractStake = new ethers.Contract(this.poolsLists[this.currentPoolIndex].address, this.getStakingNFTAbi, signer);
            } else {
              contractStake = new ethers.Contract(this.poolsLists[this.currentPoolIndex].address, this.getStakingNFTAbi, signer);
            }
            this.spinStatus = true
            let tx = await contractStake.withdraw(0, ids,{gasLimit: 500000*(ids.length)})
            await tx.wait()
            let incomeHex = await contractStake.getStakeTotalUnclaimed(this.getActiveAccount, 0)
            this.incomeAmount = ethers.utils.formatUnits(incomeHex, this.getDemicals)
            this.isAbleClaim = await contractStake.isClaimable(this.getActiveAccount)
            await this.getMyNFTLists(1)
            await this.handleGetPoolsItemData()
            this.spinStatus = false
            this.$message.success(this.$t('l.ok_tips_withdraw'))
          }
        } catch (error) {
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleShowDepositModal(index) {
      if (this.getActiveAccount) {
        this.spinStatus = true
        this.isShowDepositModal = true
        this.currentPoolIndex = index
        await this.getMyNFTLists(0)
        this.spinStatus = false
      } else {
        this.$message.error(this.$t('l.error_tips_unconnect'))
      }
    },
    async handleDepositConfirmAll() {
      let ids = []
      this.myUndepositedNFTLists.forEach(val => {
        ids.push(val.nftID)
      })
      await this.handleDepositConfirm(ids)
    },
    async handleDepositConfirm(ids) {
      if (this.getIsMainChainID) {
        try {
          if (!this.getProviderEthers || !this.getActiveAccount) {
            this.$message.error(this.$t('l.error_tips_unconnect'))
            return
          }
          if (this.currentPoolIndex > -1 && ids.length > 0) {
            this.spinStatus = true
            let signer = this.getProviderEthers.getSigner();
            let contractStake = ''
            if (this.poolsLists[this.currentPoolIndex].type == 1) {
              contractStake = new ethers.Contract(this.poolsLists[this.currentPoolIndex].address, this.getStakingNFTAbi, signer);
            } else {
              contractStake = new ethers.Contract(this.poolsLists[this.currentPoolIndex].address, this.getStakingNFTAbi, signer);
            }
            let inviteAddress = this.$getCookie('inviteAddress') ? ((this.$getCookie('inviteAddress') == this.getActiveAccount) ? '' : this.$getCookie('inviteAddress')) : ''
            let tx = await contractStake.deposit(0, ids, inviteAddress ? inviteAddress : this.emptyAddress,{gasLimit: 500000*(ids.length)})
            await tx.wait()
            await this.getMyNFTLists(0)
            await this.handleGetPoolsItemData()
            this.spinStatus = false
            this.$message.success(this.$t('l.ok_tips_deposit'))
          }
        } catch (error) {
          console.log(error)
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleDepositDop() {
      if (this.getIsMainChainID) {
        try {
          if (!this.getProviderEthers || !this.getActiveAccount) {
            this.$message.error(this.$t('l.error_tips_unconnect'))
            return
          }
          if (this.currentPoolIndex > -1) {
            this.spinStatus = true
            let signer = this.getProviderEthers.getSigner();
            let contractStake = ''
            if (this.poolsLists[this.currentPoolIndex].type == 1) {
              contractStake = new ethers.Contract(this.poolsLists[this.currentPoolIndex].address, this.getStakingNFTAbi, signer);
            } else {
              contractStake = new ethers.Contract(this.poolsLists[this.currentPoolIndex].address, this.getStakingNFTAbi, signer);
            }
            let tx = await contractStake.depositDop()
            await tx.wait()
            this.isAbleClaim = true
            this.spinStatus = false
            this.$message.success(this.$t('l.operate_ok'))
          }
        } catch (error) {
          console.log(error)
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleClaim() {
      if (this.getIsMainChainID) {
        try {
          if (!this.getProviderEthers || !this.getActiveAccount) {
            this.$message.error(this.$t('l.error_tips_unconnect'))
            return
          }
          if (this.incomeAmount <= 0) {
            this.$message.error(this.$t('l.error_tips_claim'));
            return
          }
          if (!Number(this.incomeAmount)) return
          if (this.currentPoolIndex > -1) {
            this.spinStatus = true
            let signer = this.getProviderEthers.getSigner();
            let contractStake = ''
            if (this.poolsLists[this.currentPoolIndex].type == 1) {
              contractStake = new ethers.Contract(this.poolsLists[this.currentPoolIndex].address, this.getStakingNFTAbi, signer);
            } else {
              contractStake = new ethers.Contract(this.poolsLists[this.currentPoolIndex].address, this.getStakingNFTAbi, signer);
            }
            let tx = await contractStake.claim(0)
            await tx.wait()
            let incomeHex = await contractStake.getStakeTotalUnclaimed(this.getActiveAccount, 0)
            this.incomeAmount = this.$formatDecimal(ethers.utils.formatUnits(incomeHex, this.getOkDemicals), 2)
            await this.handleGetPoolsItemData()
            this.spinStatus = false
            this.$message.success(this.$t('l.ok_tips_claim'))
          }
        } catch (error) {
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async getMyNFTLists(type) {
      // 0 未抵押的 1 抵押的
      let signer = this.getProviderEthers.getSigner();
      let provider = new ethers.providers.Web3Provider(window.ethereum)
      let contractLord = new ethers.Contract(this.getLordshipAddress, this.getLordshipAbi, signer);
      this.contractDOP = new ethers.Contract(this.getDOPAddress, this.getDOPAbi, signer);
      this.dopBalance = this.$formatDecimal(ethers.utils.formatUnits(await this.contractDOP.balanceOf(this.getActiveAccount), this.getDemicals), 0)
      //查询DOP的授权信息
      let approveAmountDOP = await this.contractDOP.allowance(this.getActiveAccount, this.poolsLists[this.currentPoolIndex].address)
      if (approveAmountDOP._hex && approveAmountDOP._hex > 0) {
        this.hasApprovedDop = true
      } else {
        this.hasApprovedDop = false
      }
      let contractStake = ''
      if (this.poolsLists[this.currentPoolIndex].type == 1) {
        contractStake = new ethers.Contract(this.poolsLists[this.currentPoolIndex].address, this.getStakingNFTAbi, signer);
      } else {
        contractStake = new ethers.Contract(this.poolsLists[this.currentPoolIndex].address, this.getStakingNFTAbi, signer);
      }
      if (type == 0) {
        this.myUndepositedNFTLists = []
        //这里只获取超级NFT
        let myNFTTotalHex = await contractLord.getLordTokens(this.getActiveAccount)
        let contractLordInfoArr = []
        let contractDopAmountArr = []
        for (let i = 0; i < myNFTTotalHex.length; i++) {
          contractLordInfoArr.push(contract('Lordship'+i,this.getLordshipAbi,this.getLordshipAddress).getTokenTraits(parseInt(myNFTTotalHex[i])))
          contractDopAmountArr.push(contract('Stake'+i,this.getStakingNFTAbi,this.getStakingNFTAddress).getDopAmount([myNFTTotalHex[i]]))
        }
        let resultLordInfoArr = await batch(provider,...contractLordInfoArr)
        let resultDopAmountArr = await batch(provider,...contractDopAmountArr)
        for(let j = 0;j < myNFTTotalHex.length;j++) {
          if (parseInt(myNFTTotalHex[j]) !== 0) {
            let nftInfo = resultLordInfoArr['Lordship'+j].getTokenTraits[0]
            let needDopAmount = ethers.utils.formatUnits(resultDopAmountArr['Stake'+j].getDopAmount[0], this.getDemicals)
            this.myUndepositedNFTLists.push({
              nftID: parseInt(myNFTTotalHex[j]),
              nftKey: this.$formatKey(parseInt(myNFTTotalHex[j])),
              level: parseInt(nftInfo.level),
              needDop: needDopAmount,
            })
          }
        }
      } else if (type == 1) {
        this.myDepositedNFTLists = []
        let myDepositedNFTIDArr = []
        myDepositedNFTIDArr = await contractStake.getTokensOnStake(this.getActiveAccount)
        let contractLordInfoArr = []
        let contractDopAmountArr = []
        for (let i = 0; i < myDepositedNFTIDArr.length; i++) {
          contractLordInfoArr.push(contract('Lordship'+i,this.getLordshipAbi,this.getLordshipAddress).getTokenTraits(parseInt(myDepositedNFTIDArr[i])))
          contractDopAmountArr.push(contract('Stake'+i,this.getStakingNFTAbi,this.getStakingNFTAddress).getDopAmount([myDepositedNFTIDArr[i]]))
        }
        let resultLordInfoArr = await batch(provider,...contractLordInfoArr)
        let resultDopAmountArr = await batch(provider,...contractDopAmountArr)
        for (let j = 0; j < myDepositedNFTIDArr.length; j++) {
          if (parseInt(myDepositedNFTIDArr[j]) !== 0) {
            let nftInfo = resultLordInfoArr['Lordship'+j].getTokenTraits[0]
            let needDopAmount = ethers.utils.formatUnits(resultDopAmountArr['Stake'+j].getDopAmount[0], this.getDemicals)
            this.myDepositedNFTLists.push({
              nftID: parseInt(myDepositedNFTIDArr[j]),
              nftKey: this.$formatKey(parseInt(myDepositedNFTIDArr[j])),
              level: nftInfo.level,
              needDop: needDopAmount,
            })
          }
        }
      }
    },
    async getPoolsData() {
      clearInterval(this.getDataInterVal)
      this.poolsLists = []
      this.spinStatus = true
      this.$http.get('./static/pools.json', {}, true).then(async (data) => {
        this.spinStatus = false
        this.poolsLists = (data && data.dbpools) ? data.dbpools : []
        await this.handleGetPoolsItemData()
        this.getDataInterVal = setInterval(async () => {
          await this.handleGetPoolsItemData()
        }, this.getDataUpdateTime * 1000)
      }).catch(err => {
        this.spinStatus = false
        console.log(err)
      })
    },
    //此函数处理所有的数据，两个分支，只判断有没有已连接账号，没有账号统一按照rpc连接
    async handleGetPoolsItemData() {
      if (this.getIsMainChainID) {
        new Promise((resolve, reject) => {
          try {
            let promiseAllArr = []
            for (let i = 0; i < this.poolsLists.length; i++) {
              promiseAllArr[i] = new Promise((resolve) => {
                if (this.poolsLists[i].address) {
                  if (!this.getActiveAccount) {
                    //未连接具体账号，按照rpc方式，只获取基本数据(日产量，总锁仓)
                    let rpc = this.$store.state.accounts.huobiNetWork[parseInt(this.$store.state.accounts.chainId ? this.$store.state.accounts.chainId : this.$store.state.accounts.mainChainID)]
                    const web3 = new Web3(rpc)
                    let contractStake = ''
                    if (this.poolsLists[i].type == 1) {
                      contractStake = new web3.eth.Contract(this.getStakingNFTAbi, this.poolsLists[i].address);
                    } else {
                      contractStake = new web3.eth.Contract(this.getStakingNFTAbi, this.poolsLists[i].address);
                    }
                    const promise1 = contractStake.methods.rewardPreDay().call().then(async (rewardPreDay) => {
                      //apy
                      // let contractOracle = new web3.eth.Contract(this.getPriceOracleAbi, this.getPriceOracleAddress);
                      // let apyHex = await contractOracle.methods.nft_pool_apy(this.poolsLists[i].address, rewardPreDay).call()
                      // this.poolsLists[i].apy = this.$formatDecimal(ethers.utils.formatUnits(apyHex, this.getDemicals), 4)
                      //rcl
                      this.poolsLists[i].rcl = ethers.utils.formatUnits(rewardPreDay, this.getDemicals)
                    })
                    const promise2 = contractStake.methods.getPoolTotalDeposited(this.poolsLists[i].poolid).call().then(totalDepositHex => {
                      this.poolsLists[i].lock = this.$formatDecimal(ethers.utils.formatUnits(totalDepositHex, 0), 0)
                    }).catch(err => { console.log(err) })
                    Promise.all([promise1, promise2]).then(() => {
                      this.poolsLists[i].isLoading = false
                      resolve('success')
                    }).catch(() => {
                      this.poolsLists[i].isLoading = false
                      resolve('success')
                    })
                  } else {
                    //已连接具体账号，除了apy和lock,还要查询授权信息和deposited数据
                    let signer = this.getProviderEthers.getSigner();
                    let contractStake = ''
                    if (this.poolsLists[i].type == 1) {
                      contractStake = new ethers.Contract(this.poolsLists[i].address, this.getStakingNFTAbi, signer);
                    } else {
                      contractStake = new ethers.Contract(this.poolsLists[i].address, this.getStakingNFTAbi, signer);
                    }
                    let contractLord = new ethers.Contract(this.getLordshipAddress, this.getLordshipAbi, signer);
                    const promise1 = contractStake.rewardPreDay().then(async (rewardPreDay) => {
                      //apy
                      // let contractOracle = new ethers.Contract(this.getPriceOracleAddress, this.getPriceOracleAbi, signer)
                      // let apyHex = await contractOracle.nft_pool_apy(this.poolsLists[i].address, rewardPreDay)
                      // this.poolsLists[i].apy = this.$formatDecimal(ethers.utils.formatUnits(rewardPreDay, this.getDemicals), 4)
                      //rcl
                      this.poolsLists[i].rcl = ethers.utils.formatUnits(rewardPreDay, this.getDemicals)
                    })
                    // //lock
                    const promise2 = contractStake.getPoolTotalDeposited(this.poolsLists[i].poolid).then(totalDepositHex => {
                      this.poolsLists[i].lock = this.$formatDecimal(ethers.utils.formatUnits(totalDepositHex, 0), 0)
                    }).catch(err => { console.log(err) })

                    // //授权
                    let promise3 = null
                    promise3 = contractLord.isApprovedForAll(this.getActiveAccount, this.poolsLists[i].address).then(isApproved => {
                      this.poolsLists[i].isApproved = isApproved
                    }).catch(() => { this.poolsLists[i].isApproved = false })
                    // //查询当前账号deposited
                    const promise4 = contractStake.getStakeTotalDeposited(this.getActiveAccount, this.poolsLists[i].poolid).then(depositedHex => {
                      this.poolsLists[i].deposited = this.$formatDecimal(ethers.utils.formatUnits(depositedHex, 0), 0)
                    }).catch(err => { console.log(err) })
                    Promise.all([promise1, promise2, promise3, promise4]).then(() => {
                      this.poolsLists[i].isLoading = false
                      resolve('success')
                    }).catch(() => {
                      this.poolsLists[i].isLoading = false
                      resolve('success')
                    })
                    this.poolsLists[i].isLoading = false
                    resolve('success')
                  }
                } else {
                  this.poolsLists[i].isLoading = false
                  resolve('success')
                }
              })
            }
            Promise.all(promiseAllArr).then(() => {
              resolve('success')
            }).catch(err => {
              reject(err)
            })
          } catch (error) {
            reject(error)
            this.$message.error(this.$t('l.catch_err'))
          }
        })
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleApprovedFor(index) {
      if (this.getIsMainChainID) {
        try {
          if (!this.getProviderEthers || !this.getActiveAccount) {
            this.$message.error(this.$t('l.error_tips_unconnect'))
            return
          }
          if (this.poolsLists.length > 0 && index > -1) {
            this.spinStatus = true
            let signer = this.getProviderEthers.getSigner();
            let contractLord = new ethers.Contract(this.getLordshipAddress, this.getLordshipAbi, signer);
            let tx = await contractLord.setApprovalForAll(this.poolsLists[index].address, true)
            await tx.wait()
            this.poolsLists[index].isApproved = true
            this.spinStatus = false
            this.$message.success(this.$t('l.approve_ok'))
          } else {
            this.$message.error(this.$t('l.catch_err'))
          }
        } catch (error) {
          console.log(error)
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleApprovedDop() {
      if (this.getIsMainChainID) {
        try {
          if (!this.getProviderEthers || !this.getActiveAccount) {
            this.$message.error(this.$t('l.error_tips_unconnect'))
            return
          }
          if (this.poolsLists.length > 0 && this.currentPoolIndex > -1) {
            this.spinStatus = true
            let signer = this.getProviderEthers.getSigner();
            this.contractDOP = new ethers.Contract(this.getDOPAddress, this.getDOPAbi, signer);
            let tx = await this.contractDOP.approve(this.poolsLists[this.currentPoolIndex].address, this.getMaxUint256)
            await tx.wait()
            this.hasApprovedDop = true
            this.spinStatus = false
            this.$message.success(this.$t('l.approve_ok'))
          } else {
            this.$message.error(this.$t('l.catch_err'))
          }
        } catch (error) {
          console.log(error)
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    }
  },
  created() {
    this.$store.dispatch("contracts/storeLordshipAbi");
    this.$store.dispatch("contracts/storeLordshipAddress");
    this.$store.dispatch("contracts/storeStakingNFTAbi");
    this.$store.dispatch("contracts/storeStakingNFTAddress");
    this.$store.dispatch("contracts/storePriceOracleAbi");
    this.$store.dispatch("contracts/storePriceOracleAddress");
    this.$store.dispatch("contracts/storeDiamondAbi");
    this.$store.dispatch("contracts/storeDiamondAddress");
    this.$store.dispatch("contracts/storeDOPAbi");
    this.$store.dispatch("contracts/storeDOPAddress");
    this.setStoreDataInterval = setInterval(async () => {
      if (!this.getStakingNFTAbi || !this.getStakingNFTAddress || !this.getLordshipAbi || !this.getLordshipAddress) {
        this.$store.dispatch("contracts/storeLordshipAbi");
        this.$store.dispatch("contracts/storeLordshipAddress");
        this.$store.dispatch("contracts/storeStakingNFTAbi");
        this.$store.dispatch("contracts/storeStakingNFTAddress");
        this.$store.dispatch("contracts/storePriceOracleAbi");
        this.$store.dispatch("contracts/storePriceOracleAddress");
        this.$store.dispatch("contracts/storeDiamondAbi");
        this.$store.dispatch("contracts/storeDiamondAddress");
        this.$store.dispatch("contracts/storeDOPAbi");
        this.$store.dispatch("contracts/storeDOPAddress");
      } else {
        clearInterval(this.setStoreDataInterval)
        await this.getPoolsData()
      }
    }, 500)
  },
  mounted() {
    let inviteAddress = this.$route.query.address ? this.$route.query.address : ''
    if(inviteAddress) {
      this.$setCookie('inviteAddress',inviteAddress,30 * 24 * 60 * 60)
    }
  },
  destroyed() {
    clearInterval(this.getDataInterVal)
  }
}
</script>

<style scoped>
.vault_inner_wrap {
  padding: 24px;
}
.pool_wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pools_item {
  width: 100%;
  max-width: 600px;
  background-color: #000;
  border-radius: 8px;
  border: 1px solid #52CCC5;
}
.pools_item_wrap {
  width: 100%;
  height: 100%;
  padding: 24px 16px;
}
.info_1 {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.info_1 img {
  width: 48px;
  height: 48px;
  margin-right: 8px;
}
.pool_title {
  font-size: 24px;
  color: #cccccc;
  font-weight: bolder;
}
.ava_token {
  font-size: 14px;
  color: #888888;
  margin-left: auto;
  white-space: wrap;
  max-width: 100px;
  text-align: right;
}
.info_2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.info_2 .title {
  font-size: 14px;
  color: #888888;
}
.info_2 .val {
  font-size: 14px;
  color: #cccccc;
  font-weight: bolder;
}
.info_2_line1 .val {
  color: #c59f52;
  font-size: 24px;
  font-weight: bolder;
}
.operate_wrap {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 16px;
}
.withdraw_btn {
  border-color: #52ccc5;
  color: #52ccc5;
}
.deposit_btn {
  background: linear-gradient(#8ae8e4, #52ccc5);
}
.mining_wrap {
  background-color: #000;
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  border: 1px solid #52ccc5;
  padding: 24px 16px;
  margin: 24px auto 0;
}
.mining_title {
  font-size: 24px;
  font-weight: bolder;
  color: #c59f52;
  margin-bottom: 16px;
  text-align: center;
}
.mining_desc {
  font-size: 14px;
  color: #888888;
}
.modal_title {
  color: #cccccc;
  font-size: 20px;
  text-align: center;
}
.modal_info1 {
  margin-bottom: 24px;
}
.table_wrap_title {
  width: 100%;
  padding: 8px;
  display: flex;
  flex-wrap: nowrap;
  font-size: 12px;
  color: #888888;
  list-style: none;
}
.table_title_item {
  flex: 1;
}
.table_wrap_title .table_title_item:nth-child(4) {
  flex: 0.7;
  text-align: center;
}
.table_title_item:last-child {
  text-align: center;
}
.table_wrap {
  width: 100%;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.modal_btn {
  width: 95%;
  height: 28px;
  border-radius: 4px;
  margin-left: 1px;
}
.table_item {
  width: 100%;
  background-color: #1f1f1f;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 8px;
  margin-bottom: 8px;
}
.table_item span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table_item span:nth-child(1) {
  flex: 1;
  font-size: 16px;
  font-weight: bolder;
  color: #C59F52;
}
.table_item span:nth-child(2) {
  flex: 1;
  font-size: 14px;
  font-weight: bolder;
  color: #888888;
}
.table_item span:nth-child(3) {
  flex: 1;
  font-size: 16px;
  font-weight: bolder;
  color: #52CCC5;
}
.table_item span:nth-child(4) {
  flex: 0.7;
}
.claim_wrap {
  padding: 16px;
  border-radius: 4px;
  background-color: #1f1f1f;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.claim_info_wrap {
  display: flex;
  flex-direction: column;
}
.claim_info_wrap span:nth-child(1) {
  font-size: 12px;
  color: #888888;
  margin-bottom: 8px;
}
.claim_info_wrap span:nth-child(2) {
  font-size: 16px;
  color: #CCCCCC;
  font-weight: bolder;
}
.claim_btn {
  max-width: 104px;
  border-radius: 4px;
}
.deposit_all_wrap {
  margin-top: 16px;
}
.deposit_all_title {
  font-size: 12px;
  color: #888888;
  margin-bottom: 8px;
}
.deposit_all_info {
  background-color: #1f1f1f;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 8px;
  margin-bottom: 8px;
}
.deposit_all_info .num {
  font-size: 16px;
  font-weight: bolder;
  color: #C59F52;
}
.deposit_all_btn {
  height: 28px;
  max-width: 90px;
  margin-left: auto;
  border-radius: 4px;
}
</style>

<style>
.deposit_modal .ant-modal-content {
  border: 1px solid #c59f52;
  background-color: #000;
  border-radius: 8px !important;
}
.deposit_modal .anticon {
  color: #cccccc;
}
.deposit_modal .ant-modal-body {
  padding: 24px 16px;
}
</style>