<template>
  <div class="vault_wrap">
    <Spin class="global_loading" tip="loading" :spinning="spinStatus" size="large">
      <div class="nft_wrap">
        <div class="box_top">
          <img class="box" src="../assets/box.png" alt="">
          <div class="info_box left_box">
            <span class="title">{{$t('l.left_box')}}</span>
            <span class="val"><countTo :endVal='remainBall/1' :duration='3000' :decimals="0"></countTo> {{$t('l.ge')}}</span>
          </div>
          <div class="price_box">
            <p class="balance">{{$t('l.balance')}}: <countTo :endVal='accountBalanceWFC/1' :duration='3000' :decimals="0"></countTo>{{$t('l.token1')}}</p>
            <div class="price_info">
              <span class="title">{{$t('l.value')}}50U</span>
              <span class="val token1"><countTo :endVal='(uintPriceWFC * getTypeArr[currentGetIndex])/1' :duration='3000' :decimals="4"></countTo> {{$t('l.token1')}}</span>
            </div>
          </div>
          <div class="price_box">
            <p class="balance">{{$t('l.balance')}}: <countTo :endVal='accountBalanceDOP/1' :duration='3000' :decimals="0"></countTo>{{$t('l.token2')}}</p>
            <div class="price_info">
              <span class="title">{{$t('l.value')}}200U</span>
              <span class="val token2"><countTo :endVal='(uintPriceDop * getTypeArr[currentGetIndex])/1' :duration='3000' :decimals="4"></countTo> {{$t('l.token2')}}</span>
            </div>
          </div>
          <ul class="buy_type">
            <li class="buy_type_item" :class="currentGetIndex == index ? 'active' : ''" v-for="(item,index) in getTypeArr" :key="index" @click="handleChooseBuyType(index)">{{$t('l.buy')}} {{item}} {{$t('l.ge')}}</li>
          </ul>
          <div class="buy_btn edit_btn full_btn" v-if="hasApproved" @click="handleMintConfirm">{{$t('l.buy')}}</div>
          <div class="buy_btn edit_btn full_btn" v-else @click="handleApprovedFor">{{$t('l.approve')}}</div>
          <div class="my_nft" @click="handleJump">{{$t('l.my_nft')}}</div>
        </div>
        <div class="box_bottom">
          <p class="nft_box_title">{{$t('l.box_title')}}</p>
          <p class="nft_box_desc">{{$t('l.box_desc')}}</p>
        </div>
      </div>
      <Modal class="buy_modal" v-model="isShowBuyModal" :footer="null" :width="!$store.state.accounts.isMobile ? '375px' : '90%'" height="90%" @cancel="handleBuyModalCancel" :centered="true" :destroyOnClose="true" :dialog-style="{ top: '30px' }">
        <div class="buy_modal_wrap">
          <div class="pic_wrap pic_wrap1" v-if="currentGetIndex == 0">
            <img :src="mintTokenArr.length > 0 ? mintTokenArr[0].tokenURI : ''" alt="">
            <div class="num">{{mintTokenArr.length > 0 ? mintTokenArr[0].nftKey : ''}}</div>
            <div class="level">LV{{mintTokenArr.length > 0 ? mintTokenArr[0].level : ''}}</div>
          </div>
          <div class="pic_wrap pic_two" v-else>
            <div class="buy_img">
              <div class="card_num">{{cardTotalByType[1]}}</div>
              <img src="../assets/buy_fouth.png" alt="">
            </div>
            <div class="buy_img">
              <div class="card_num">{{cardTotalByType[0]}}</div>
              <img src="../assets/buy_five.png" alt="">
            </div>
            <div class="buy_img">
              <div class="card_num">{{cardTotalByType[3]}}</div>
              <img src="../assets/buy_two.png" alt="">
            </div>
            <div class="buy_img">
              <div class="card_num">{{cardTotalByType[4]}}</div>
              <img src="../assets/buy_three.png" alt="">
            </div>
            <div class="buy_img">
              <div class="card_num">{{cardTotalByType[2]}}</div>
              <img src="../assets/buy_one.png" alt="">
            </div>
          </div>
          <div class="edit_btn confirm_btn" @click="handleBuyModalCancel">{{$t('l.confirm')}}</div>
        </div>
      </Modal>
    </Spin>
  </div>
</template>

<script>
import { Modal,Spin } from 'ant-design-vue'
import { mapGetters } from 'vuex'
import { ethers } from 'ethers';
import Web3 from 'web3'
import countTo from 'vue-count-to'
export default {
  components: {
    Modal,
    Spin,
    countTo
  },
  computed: {
    ...mapGetters('accounts',['getActiveAccount','getProviderEthers','getDemicals','getDataUpdateTime','getIsMainChainID','emptyAddress']),
    ...mapGetters('contracts',['getMaxUint256','getWFCAbi','getWFCAddress','getDOPAbi','getDOPAddress','getLordshipAbi','getLordshipAddress'])
  },
  data() {
    return {
      spinStatus: false,
      getTypeArr: [1,5],
      currentGetIndex: 0,
      isShowBuyModal: false,
      remainBall: 0,
      totalMint: 0,
      getHasApprovedInterVal: null,
      contractWFC: null,
      contractDOP: null,
      hasApproved: false,
      uintPriceDop: 0,
      uintPriceWFC: 0,
      accountBalanceDOP: 0,
      accountBalanceWFC: 0,
      cardTotalByType: Array(5).fill(0),//保存抽取的各个类型卡片的数量
      mintTokenArr: [],
    }
  },
  methods: {
    handleChooseBuyType(index) {
      this.currentGetIndex = index
    },
    handleBuyModalCancel() {
      this.isShowBuyModal = false
    },
    async handleMintConfirm() {
      if (this.getIsMainChainID) {
        try {
          if (!this.getProviderEthers || !this.getActiveAccount) {
            this.$message.error(this.$t('l.error_tips_unconnect'))
            return
          }
          if (this.remainBall <= 0) {
            this.$message.error(this.$t('l.mint_done_tips'))
            return
          }
          let inviteAddress = this.$getCookie('inviteAddress') ? ((this.$getCookie('inviteAddress') == this.getActiveAccount) ? '' : this.$getCookie('inviteAddress')) : ''
          this.spinStatus = true
          let signer = this.getProviderEthers.getSigner();
          this.contractLord = new ethers.Contract(this.getLordshipAddress, this.getLordshipAbi, signer);
          let tx = await this.contractLord.mint(this.getTypeArr[this.currentGetIndex],inviteAddress ? inviteAddress : this.emptyAddress,{ gasLimit: 500000 * this.getTypeArr[this.currentGetIndex] })
          let receipt = await tx.wait();
          const iface = new ethers.utils.Interface(["event TokenMinted(address indexed user, uint256[] tokenIds)"])
          let log = receipt.logs.filter(log => log.address == this.getLordshipAddress)
          let result = iface.parseLog(log[this.getTypeArr[this.currentGetIndex]])
          let mintTokenID = []
          this.mintTokenArr = []
          this.cardTotalByType = []
          if(result.args.user.toLowerCase() == this.getActiveAccount) {
            mintTokenID = result.args.tokenIds.map(val => parseInt(val))
            //全部获取结束，获取nft详情后做展示
            if(mintTokenID.length == this.getTypeArr[this.currentGetIndex]) {
              this.cardTotalByType = Array(5).fill(0)
              for(let i = 0;i < mintTokenID.length;i++) {
                let info = await this.contractLord.getTokenTraits(mintTokenID[i])
                this.mintTokenArr.push({
                  id: mintTokenID[i],
                  nftKey: this.$formatKey(mintTokenID[i]),
                  level: info.level,
                  type: info.role,
                  tokenURI: await this.contractLord.tokenURI(mintTokenID[i])
                })
                this.cardTotalByType[info.role - 1]++
              }
            }
            await this.getInitData()
            this.spinStatus = false
            this.isShowBuyModal = true
            this.$message.success(this.$t('l.operate_ok'))
          }
        } catch (error) {
          console.log(error)
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    handleJump() {
      this.$router.push({path: "mynft"})
    },
    async handleApprovedFor() {
      if (this.getIsMainChainID) {
        try {
          if (!this.getProviderEthers || !this.getActiveAccount) {
            this.$message.error(this.$t('l.error_tips_unconnect'))
            return
          }
          this.spinStatus = true
          let tx1 = await this.contractWFC.approve(this.getLordshipAddress, this.getMaxUint256)
          let tx2 = await this.contractDOP.approve(this.getLordshipAddress, this.getMaxUint256)
          await tx1.wait()
          await tx2.wait()
          this.hasApproved = true
          this.spinStatus = false
          this.$message.success(this.$t('l.approve_ok'))
        } catch (error) {
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async getInitData() {
      if (this.getIsMainChainID) {
        this.spinStatus = true
        try {
          if (!this.getActiveAccount) {
            //未连接账号,查剩余数量，单价
            let rpc = this.$store.state.accounts.huobiNetWork[parseInt(this.$store.state.accounts.chainId ? this.$store.state.accounts.chainId : this.$store.state.accounts.mainChainID)]
            const web3 = new Web3(rpc)
            this.contractLord = new web3.eth.Contract(this.getLordshipAbi, this.getLordshipAddress);
            this.totalMint = parseInt(await this.contractLord.methods.MAX_ROUND_TOKENS().call())
            let mintedBall = parseInt(await this.contractLord.methods.minted().call())
            this.remainBall = this.totalMint - mintedBall <= 0 ? 0 : (this.totalMint - mintedBall)
            this.uintPriceDop = ethers.utils.formatUnits(await this.contractLord.methods.mintCostDop().call(), this.getDemicals)
            this.uintPriceWFC = ethers.utils.formatUnits(await this.contractLord.methods.mintCostWfc().call(), this.getDemicals)
          } else {
            //连接了账号，查剩余数量，单价，余额
            let signer = this.getProviderEthers.getSigner();
            this.contractLord = new ethers.Contract(this.getLordshipAddress, this.getLordshipAbi, signer);
            this.totalMint = parseInt(await this.contractLord.MAX_ROUND_TOKENS())
            let mintedBall = parseInt(await this.contractLord.minted())
            this.remainBall = this.totalMint - mintedBall <= 0 ? 0 : (this.totalMint - mintedBall)
            this.uintPriceDop = ethers.utils.formatUnits(await this.contractLord.mintCostDop(), this.getDemicals)
            this.uintPriceWFC = ethers.utils.formatUnits(await this.contractLord.mintCostWfc(), this.getDemicals)
            this.accountBalanceDOP = ethers.utils.formatUnits(await this.contractDOP.balanceOf(this.getActiveAccount),this.getDemicals)
            this.accountBalanceWFC = ethers.utils.formatUnits(await this.contractWFC.balanceOf(this.getActiveAccount),this.getDemicals)
          }
          this.spinStatus = false
        } catch (error) {
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    }
  },
  mounted() {
    let inviteAddress = this.$route.query.address ? this.$route.query.address : ''
    if(inviteAddress) {
      this.$setCookie('inviteAddress',inviteAddress,30 * 24 * 60 * 60)
    }
    let timeInterval = setInterval(async () => {
      if (!this.getWFCAbi || !this.getWFCAddress || !this.getDOPAbi || !this.getDOPAddress || !this.getLordshipAbi || !this.getLordshipAddress) {
        this.$store.dispatch("contracts/storeWFCAbi");
        this.$store.dispatch("contracts/storeWFCAddress");
        this.$store.dispatch("contracts/storeDOPAbi");
        this.$store.dispatch("contracts/storeDOPAddress");
        this.$store.dispatch("contracts/storeLordshipAbi");
        this.$store.dispatch("contracts/storeLordshipAddress");
      } else {
        clearInterval(timeInterval)
        await this.getInitData()
      }
    }, 500)
    this.getHasApprovedInterVal = setInterval(async () => {
      if (this.getWFCAbi && this.getWFCAddress && this.getDOPAbi && this.getDOPAddress) {
        if (this.getIsMainChainID) {
          if (this.getActiveAccount) {
            let signer = this.getProviderEthers.getSigner();
            this.contractWFC = new ethers.Contract(this.getWFCAddress, this.getWFCAbi, signer);
            this.contractDOP = new ethers.Contract(this.getDOPAddress, this.getDOPAbi, signer);
            //查询授权信息
            let approveAmountWFC = await this.contractWFC.allowance(this.getActiveAccount, this.getLordshipAddress)
            let approveAmountDOP = await this.contractDOP.allowance(this.getActiveAccount, this.getLordshipAddress)
            if (approveAmountWFC._hex && approveAmountWFC._hex > 0 && approveAmountDOP._hex && approveAmountDOP._hex > 0) {
              this.hasApproved = true
              clearInterval(this.getHasApprovedInterVal)
            } else {
              this.hasApproved = false
            }
          }
        } else {
          this.$message.error(this.$t('l.no_main_tips'))
        }
      }
    }, 500)
  }
}
</script>

<style scoped>
.nft_wrap {
  width: 100%;
  background-color: #0d1112;
  padding: 16px 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box_top,.box_bottom {
  width: 70%;
  max-width: 600px;
  border: 1px solid #52CCC5;
  background-color: #000;
  border-radius: 8px;
  padding: 32px 16px 16px;
}
.box_top img {
  width: 252px;
  height: auto;
}
.info_box {
  width: 100%;
  background-color: #141414;
  border-radius: 8px;
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}
.info_box .title {
  color: #888888;
  font-size: 14px;
}
.info_box .val {
  color: #CCCCCC;
  font-size: 20px;
  font-weight: bolder;
}
.price_box {
  width: 100%;
  background-color: #141414;
  border-radius: 8px;
  padding: 6px 12px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}
.price_box .balance {
  text-align: right;
  font-size: 12px;
  color: #CCCCCC;
  margin-bottom: 4px;
}
.price_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price_info .title {
  font-size: 14px;
  color: #888888;
}
.price_info .val {
  font-size: 20px;
  font-weight: bolder;
}
.price_info .val.token1 {
  color: #C59F52;
}
.price_info .val.token2 {
  color: #52CCC5;
}
.buy_type {
  width: 100%;
  display: flex;
  list-style: none;
  margin-top: 8px;
}
.buy_type_item {
  padding: 6px 16px;
  border: 1px solid #555555;
  color: #555555;
  cursor: pointer;
  border-radius: 16px;
}
.buy_type_item:first-child {
  margin-right: 4px;
}
.buy_type_item.active {
  border: 1px solid #52CCC5;
  color: #52CCC5;
}
.buy_btn {
  margin-top: 24px;
}
.my_nft {
  font-size: 14px;
  color: #00CFC6;
  cursor: pointer;
  margin-top: 16px;
}
.box_bottom {
  margin-top: 16px;
}
.nft_box_title {
  font-size: 24px;
  color: #C59F52;
  font-weight: bolder;
  margin-bottom: 16px;
}
.nft_box_desc {
  font-size: 14px;
  color: #888888;
  text-align: left;
  margin-bottom: 8px;
}
.buy_modal_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pic_wrap {
  width: 100%;
  position: relative;
}
.pic_wrap1 {
  width: 311px;
}
.pic_wrap img {
  width: 100%;
  height: auto;
}
.num {
  position: absolute;
  bottom: 10px;left: 50%;
  transform: translateX(-50%);
  font-family: aliheavy;
  color: #FFCB05;
  -webkit-text-stroke: 2px #316ab2;
  font-size: 20px;
}
.level {
  position: absolute;
  font-family: alibold;
  font-size: 20px;
  color: #C59F52;
  top: 10px;right: 20px;
}
.confirm_btn {
  width: 136px;
  margin: 32px auto 0 auto;
}
.pic_two {
  position: relative;
  height: 450px;
}
.buy_img {
  position: absolute;
}
.buy_img img {
  width: 160px;
  height: auto;
}
.buy_img:nth-child(1) {
  top: 0;left: 10px;
  z-index: 1;
}
.buy_img:nth-child(1) .card_num {
  position: absolute;
  top: 18px;left: 10px;
}
.buy_img:nth-child(2) {
  top: 0;right: 10px;
  z-index: 2;
}
.buy_img:nth-child(2) .card_num {
  position: absolute;
  top: 10px;left: 22px;
}
.buy_img:nth-child(3) {
  top: 120px;left: -6px;
  z-index: 3;
}
.buy_img:nth-child(3) .card_num {
  position: absolute;
  top: 18px;left: 10px;
}
.buy_img:nth-child(4) {
  top: 120px;right: -6px;
  z-index: 4;
}
.buy_img:nth-child(4) .card_num {
  position: absolute;
  top: 10px;left: 22px;
}
.buy_img:nth-child(5) {
  top: 225px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}
.buy_img:nth-child(5) .card_num {
  position: absolute;
  top: 8px;left: 8px;
}
.card_num {
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #C59F52;
  color: #C59F52;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .box_top,.box_bottom {
    width: 100%;
  }
}
</style>

<style>
  .buy_modal .ant-modal-content {
    background-color: transparent;
  }
  .buy_modal .ant-modal-mask {
    background-color: rgba(0,0,0,.7);
  }
  .buy_modal .anticon {
    color: #fff;
  }
  .buy_modal .ant-modal-close {
    display: none;
  }
  @media (max-width: 768px) {
    .buy_modal .ant-modal-body {
      padding: 0;
    }
  }
</style>