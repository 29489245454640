<template>
  <div class="vault_wrap">
    <Spin class="global_loading" tip="loading" :spinning="spinStatus" size="large">
      <div class="my_nft_wrap">
        <div class="vault_top_nav">
          <ul class="vault_nav">
            <li v-for="(item,index) in navArr" :key="index" @click="handleContChange(index)" class="valut_nav_item" :class="currentIndex == index ? 'active' : ''">{{$t('l.'+item)}}</li>
            <div id="donghuaBox" :style="{left: (currentIndex*50)+'%'}"></div>
          </ul>
          <div class="my_balance">{{$t('l.diamond')}}: <countTo :endVal='accountBalance/1' :duration='3000' :decimals="0"></countTo>{{$t('l.ge')}}</div>
        </div>
        <div class="copy_link">
          <span class="link_title">{{$t('l.invite_link')}}</span>
          <div class="link_wrap">
            <span class="link_val">{{currentHref+'#/nftmining?address='+getActiveAccount}}</span>
            <img @click="handleCopyLink" class="copy_icon" src="../assets/invite_icon.svg" alt="">
          </div>
        </div>
        <ul class="card_wrap">
          <li class="card_item" v-for="(item,index) in dataLists" :key="index">
            <div class="img_wrap" v-if="item && item.tokenURI">
              <img :src="item ? item.tokenURI : ''" alt="">
              <div class="card_item_level" v-if="!item.isHero">LV{{item.level}}</div>
            </div>
            <div class="card_info">
              <p class="card_info_item">
                <span class="card_title">{{$t('l.num')}}</span>
                <span class="card_val">{{item.nftKey}}</span>
              </p>
            </div>
            <div class="card_info">
              <p class="card_info_item status">
                <span class="card_title">{{$t('l.status')}}</span>
                <span class="card_val">{{item.isForSale ? $t('l.sell') : $t('l.bag')}}</span>
              </p>
            </div>
            <div class="card_info">
              <p class="card_info_item">
                <span class="card_title">{{$t('l.price')}}</span>
                <span class="card_val">
                  <span v-if="currentIndex == 1">{{item.wantSalePrice}}</span>
                  <input class="sale_ipt" type="text" @input="input_num(index)" v-model="item.wantSalePrice" placeholder="0" v-if="currentIndex == 0">
                </span>
              </p>
            </div>
            <div class="card_info">
              <div class="edit_btn ghost_btn sale_btn sale_btn_ghost" v-if="currentIndex == 0 && !item.isHero"  @click="handleShowUpdateModal(index)">{{hasApproved ? $t('l.update') : $t('l.approve')}}</div>
              <div class="edit_btn sale_btn" :class="currentIndex == 0 ? (item.isHero ? 'full_btn' : '') : 'full_btn'" @click="handleSetStatus(item.isForSale,item.nftID,index)">{{item.isForSale ? $t('l.unsale') : (hasApproved ? $t('l.sale') : $t('l.approve'))}}</div>
            </div>
          </li>
        </ul>
        <div class="operate">
          <div class="jump_wrap">
            <input class="jump_ipt" v-model="targetJumpPage" /><span class="line">/</span><span class="total_page">{{pageTotal == 0 ? 1 : pageTotal}}</span>
            <Button class="jump_btn shadow_btn" @click="handleJumpToTargetPage">{{$t('l.go')}}</Button>
          </div>
          <div class="operate_btn_wrap">
            <Button class="paga_btn shadow_btn" type="primary" @click="handlePageChange('prev')">{{$t('l.prev')}}</Button>
            <Button class="paga_btn shadow_btn" type="primary" @click="handlePageChange('next')">{{$t('l.next')}}</Button>
          </div>
        </div>
        <Modal class="update_modal" v-model="isShowUpdateModal" :footer="null" width="300px" height="90%" @cancel="handleUpdateCancel" :centered="true" :destroyOnClose="true" :dialog-style="{ top: '30px' }">
          <div class="update_modal_wrap">
            <div class="modal_img_wrap">
              <img class="update_img" :src="(dataLists.length > 0 && currentUpgradeIndex > -1) ? dataLists[currentUpgradeIndex].tokenURI : ''" alt="">
              <div class="card_item_level">LV{{(dataLists.length > 0 && currentUpgradeIndex > -1) ? dataLists[currentUpgradeIndex].level : 0}}</div>
            </div>
            <p class="num_text">
              <span class="num_text_title">{{$t('l.num')}}</span>
              <span class="num_text_val">{{(dataLists.length > 0 && currentUpgradeIndex > -1) ? dataLists[currentUpgradeIndex].nftKey : '0000'}}</span>
            </p>
            <div class="update_info">
              <p class="balance">{{$t('l.left_diamond')}}: {{accountBalance}}</p>
              <div class="cost">
                <span class="cost_title">{{$t('l.update_cost')}}</span>
                <span class="cost_val">{{(dataLists.length > 0 && currentUpgradeIndex > -1) ? dataLists[currentUpgradeIndex].upgradeFee : 0}}</span>
              </div>
            </div>
            <div class="update_btn_wrap">
              <div class="edit_btn ghost_btn" @click="handleUpdateCancel">{{$t('l.cancel')}}</div>
              <div class="edit_btn" @click="handleUpGrade">{{$t('l.confirm')}}</div>
            </div>
          </div>
        </Modal>
      </div>
    </Spin>
  </div>
</template>

<script>
import { Modal,Spin } from 'ant-design-vue'
import { mapGetters } from 'vuex'
import { ethers } from 'ethers';
import countTo from 'vue-count-to'
import { batch, contract } from '@pooltogether/etherplex'
export default {
  components: {
    Modal,
    Spin,
    countTo
  },
  computed: {
    ...mapGetters('accounts',['getActiveAccount','getProviderEthers','getDemicals','getDataUpdateTime','getIsMainChainID']),
    ...mapGetters('contracts',['getMaxUint256','getDiamondAbi','getDiamondAddress','getLordshipAbi','getLordshipAddress'])
  },
  data() {
    return {
      spinStatus: false,
      currentIndex: 0,
      navArr: ['bag', 'sell'],
      currentHref: window.location.origin + window.location.pathname,
      isShowUpdateModal: false,
      targetJumpPage: 1,
      currentPage: 1,
      pageTotal: 1,
      pageSize: 8,
      contractDiamond: null,
      getHasApprovedInterVal: null,
      hasApproved: false,
      dataLists: [],
      accountBalance: 0,
      currentUpgradeIndex: -1
    }
  },
  methods: {
    async handleContChange(index) {
      this.currentIndex = index
      this.targetJumpPage = 1
      await this.getMyNFTLists(this.currentIndex)
    },
    handleCopyLink() {
      this.$copyText(this.currentHref + '#/nftmining?address='+this.getActiveAccount).then(() => {
        this.$message.success(this.$t('l.copy_success'))
      }, function () {
        this.$message.error(this.$t('l.copy_success'))
      })
    },
    async handleShowUpdateModal(index) {
      if(!this.hasApproved) {
        await this.handleApprovedFor()
        return
      }
      this.currentUpgradeIndex = index
      this.isShowUpdateModal = true
    },
    handleUpdateCancel() {
      this.currentUpgradeIndex = -1
      this.isShowUpdateModal = false
    },
    async handleSetStatus(isForSale, id, index) {
      if(!this.hasApproved) {
        await this.handleApprovedFor()
        return
      }
      if(!isForSale) {
        await this.handleAbleSale(id, index)
      }else {
        await this.handleDisableSale(id)
      }
    },
    async handleDisableSale(id) {
      if (this.getActiveAccount && id) {
        try {
          this.spinStatus = true
          let signer = this.getProviderEthers.getSigner();
          this.contractLord = new ethers.Contract(this.getLordshipAddress, this.getLordshipAbi, signer);
          let tx = await this.contractLord.setDisableForSale(id)
          await tx.wait()
          this.$message.success(this.$t('l.operate_ok'))
          await this.getMyNFTArrs()
          this.spinStatus = false
        } catch (error) {
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
        this.spinStatus = false
      } else {
        this.$message.error(this.$t('l.error_tips_unconnect'))
      }
    },
    async handleAbleSale(id, index) {
      if (this.getActiveAccount && id) {
        try {
          if (this.dataLists[index].wantSalePrice > 0 && Number(this.dataLists[index].wantSalePrice)) {
            this.spinStatus = true
            const num = "0x" + (this.dataLists[index].wantSalePrice * (10 ** this.getDemicals)).toString(16);
            let signer = this.getProviderEthers.getSigner();
            this.contractLord = new ethers.Contract(this.getLordshipAddress, this.getLordshipAbi, signer);
            let tx = await this.contractLord.setAvalibleForSale(id, num)
            await tx.wait()
            this.$message.success(this.$t('l.operate_ok'))
            await this.getMyNFTArrs()
            this.spinStatus = false
          } else {
            this.$message.error(this.$t('l.price_error'))
          }
        } catch (error) {
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.error_tips_unconnect'))
      }
    },
    async handleUpGrade() {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          try {
            this.spinStatus = true
            let signer = this.getProviderEthers.getSigner();
            this.contractLord = new ethers.Contract(this.getLordshipAddress, this.getLordshipAbi, signer);
            this.contractDiamond = new ethers.Contract(this.getDiamondAddress, this.getDiamondAbi, signer);
            let tx = await this.contractLord.upgradeLevel(this.dataLists[this.currentUpgradeIndex].nftID)
            await tx.wait()
            this.accountBalance = this.$formatDecimal(ethers.utils.formatUnits(await this.contractDiamond.balanceOf(this.getActiveAccount),this.getDemicals),0)
            this.$message.success(this.$t('l.operate_ok'))
            // this.isShowUpdateModal = false
            // this.currentUpgradeIndex = -1
            await this.getMyNFTLists(this.currentIndex)
            this.spinStatus = false
          } catch (error) {
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async getMyNFTArrs() {
      //获取我的全部，在售，未售NFT ID 数组
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          this.spinStatus = true
          this.myNFTIDArr = []
          this.myNFTIDSalesArr = []
          this.myNFTIDUnsaleArr = []
          try {
            let signer = this.getProviderEthers.getSigner();
            this.contractLord = new ethers.Contract(this.getLordshipAddress, this.getLordshipAbi, signer);
            //查余额
            this.contractDiamond = new ethers.Contract(this.getDiamondAddress, this.getDiamondAbi, signer);
            this.accountBalance = this.$formatDecimal(ethers.utils.formatUnits(await this.contractDiamond.balanceOf(this.getActiveAccount),this.getDemicals),0)
            //查询我的全部NFT  fixme
            this.myNFTIDArr = (await this.contractLord.getTokensByAddress(this.getActiveAccount)).map(val => parseInt(val))
            this.myNFTIDSalesArr = (await this.contractLord.getTokensOnSale(this.getActiveAccount)).map(val => parseInt(val))
            this.myNFTIDSalesAr = []
            this.myNFTIDArr.forEach(val => {
              !this.myNFTIDSalesArr.includes(val) && this.myNFTIDUnsaleArr.push(val)
            })
            await this.getMyNFTLists(this.currentIndex)
            this.spinStatus = false
          } catch (error) {
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async getMyNFTLists(type) {
      //根据当前菜单类别获取NFT列表，并做好分页
      //0 unsale 1 sale
      this.currentPage == 0 && (this.currentPage = 1)
      if (type !== this.currentIndex) {
        //切换了菜单，页码归一
        this.targetJumpPage = 1
        this.currentPage = 1
      }
      if (!this.getActiveAccount) {
        this.$message.error(this.$t('l.error_tips_unconnect'))
        return
      }
      try {
        this.dataLists = []
        this.spinStatus = true
        if (type == 0) {
          this.pageTotal = this.$pageCount(this.myNFTIDUnsaleArr.length, this.pageSize)
          this.currentPage > this.pageTotal ? (this.currentPage = this.pageTotal) : ''
          let currentPageIDs = this.$pagination(this.currentPage, this.pageSize, this.myNFTIDUnsaleArr)
          this.dataLists = await this.getNFTListsByIDs(currentPageIDs)
        } else if (type == 1) {
          this.pageTotal = this.$pageCount(this.myNFTIDSalesArr.length, this.pageSize)
          this.currentPage > this.pageTotal ? (this.currentPage = this.pageTotal) : ''
          let currentPageIDs = this.$pagination(this.currentPage, this.pageSize, this.myNFTIDSalesArr)
          this.dataLists = await this.getNFTListsByIDs(currentPageIDs)
        }
        this.spinStatus = false
      } catch (error) {
        this.spinStatus = false
        this.$message.error(this.$t('l.catch_err'))
      }
    },
    async getNFTListsByIDs(ids) {
      let provider = new ethers.providers.Web3Provider(window.ethereum)
      let nftLists = []
      let contractInfoArr = []
      let contractTokenURIArr = []
      let contractIsForSaleArr = []
      let contractSalePrice = []
      let contractUpgradeFeeArr = []
      for (let i = 0; i < ids.length; i++) {
        contractInfoArr.push(contract('Lordship'+i,this.getLordshipAbi,this.getLordshipAddress).getTokenTraits(ids[i]))
        contractTokenURIArr.push(contract('Lordship'+i,this.getLordshipAbi,this.getLordshipAddress).tokenURI(ids[i]))
        contractIsForSaleArr.push(contract('Lordship'+i,this.getLordshipAbi,this.getLordshipAddress).forSale(ids[i]))
        contractSalePrice.push(contract('Lordship'+i,this.getLordshipAbi,this.getLordshipAddress).tokenPrice(ids[i]))
        contractUpgradeFeeArr.push(contract('Lordship'+i,this.getLordshipAbi,this.getLordshipAddress).getUpgradeToken(ids[i]))
      }
      let resultInfoArr = await batch(provider,...contractInfoArr)
      let resultTokenURIArr = await batch(provider,...contractTokenURIArr)
      let resultIsForSaleArr = await batch(provider,...contractIsForSaleArr)
      let resultSalePriceArr = await batch(provider,...contractSalePrice)
      let resultUpgradeFeeArr = await batch(provider,...contractUpgradeFeeArr)
      for(let j = 0;j < ids.length;j++) {
        let nftInfo = resultInfoArr['Lordship'+j].getTokenTraits[0]
        let wantSalePrice = this.$formatDecimal(ethers.utils.formatUnits(resultSalePriceArr['Lordship'+j].tokenPrice[0],this.getDemicals),0)
        nftLists.push({
          nftID: ids[j],
          nftKey: this.$formatKey(ids[j]),
          level: parseInt(nftInfo.level),
          tokenURI: resultTokenURIArr['Lordship'+j].tokenURI[0],
          isForSale: resultIsForSaleArr['Lordship'+j].forSale[0],
          wantSalePrice: wantSalePrice == 0 ? undefined : wantSalePrice,
          upgradeFee: (ethers.utils.formatUnits(resultUpgradeFeeArr['Lordship'+j].getUpgradeToken[0],this.getDemicals)/1).toFixed(4),
          isHero: nftInfo.isHero
        })
      }
      this.spinStatus = false
      return nftLists
    },
    async handleApprovedFor() {
      if (this.getIsMainChainID) {
        try {
          if (!this.getProviderEthers || !this.getActiveAccount) {
            this.$message.error(this.$t('l.error_tips_unconnect'))
            return
          }
          this.spinStatus = true
          let tx = await this.contractDiamond.approve(this.getLordshipAddress, this.getMaxUint256)
          await tx.wait()
          this.hasApproved = true
          this.spinStatus = false
          this.$message.success(this.$t('l.approve_ok'))
        } catch (error) {
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleJumpToTargetPage() {
      if (!this.targetJumpPage || !Number(this.targetJumpPage)) {
        return
      }
      if (parseInt(this.targetJumpPage) <= 0) {
        this.currentPage = 1
        this.targetJumpPage = 1
        return
      } else if (parseInt(this.targetJumpPage) >= this.pageTotal) {
        this.targetJumpPage = this.pageTotal
        if(this.currentPage !== parseInt(this.targetJumpPage)) {
          this.currentPage = this.targetJumpPage
          await this.getMyNFTLists(this.currentIndex)
        }else {
          this.currentPage = this.targetJumpPage
        }
        return
      } else {
        if(this.currentPage == parseInt(this.targetJumpPage)) {
          return
        }else {
          this.currentPage = this.targetJumpPage
        }
      }
      await this.getMyNFTLists(this.currentIndex)
    },
    async handlePageChange(type) {
      if (type == 'prev') {
        if (this.currentPage > 1) {
          this.currentPage--
        } else {
          return
        }
      } else if (type == 'next') {
        if (this.currentPage < this.pageTotal) {
          this.currentPage++
        } else {
          return
        }
      }
      this.targetJumpPage = this.currentPage
      await this.getMyNFTLists(this.currentIndex)
    },
    input_num(index) {
      this.dataLists[index].wantSalePrice = this.dataLists[index].wantSalePrice.replace(/[^\d.]/g, "")
      this.dataLists[index].wantSalePrice = this.dataLists[index].wantSalePrice.replace(/\.{2,}/g, ".")
      this.dataLists[index].wantSalePrice = this.dataLists[index].wantSalePrice.replace(/^\./g, "")
      this.dataLists[index].wantSalePrice = this.dataLists[index].wantSalePrice.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
      this.dataLists[index].wantSalePrice = this.dataLists[index].wantSalePrice.replace(/^(-)*(\d+)\.()*$/, '$1$2')
    },
  },
  mounted() {
    let inviteAddress = this.$route.query.address ? this.$route.query.address : ''
    if(inviteAddress) {
      this.$setCookie('inviteAddress',inviteAddress,30 * 24 * 60 * 60)
    }
    let timeInterval = setInterval(async () => {
      if (!this.getDiamondAbi || !this.getDiamondAddress || !this.getLordshipAbi || !this.getLordshipAddress) {
        this.$store.dispatch("contracts/storeDiamondAbi");
        this.$store.dispatch("contracts/storeDiamondAddress");
        this.$store.dispatch("contracts/storeLordshipAbi");
        this.$store.dispatch("contracts/storeLordshipAddress");
      } else {
        clearInterval(timeInterval)
        await this.getMyNFTArrs()
      }
    }, 500)
    this.getHasApprovedInterVal = setInterval(async () => {
      if (this.getDiamondAbi && this.getDiamondAddress) {
        if (this.getIsMainChainID) {
          if (this.getActiveAccount) {
            let signer = this.getProviderEthers.getSigner();
            this.contractDiamond = new ethers.Contract(this.getDiamondAddress, this.getDiamondAbi, signer);
            //查询授权信息
            let approveAmount = await this.contractDiamond.allowance(this.getActiveAccount, this.getLordshipAddress)
            if (approveAmount._hex && approveAmount._hex > 0) {
              this.hasApproved = true
              clearInterval(this.getHasApprovedInterVal)
            } else {
              this.hasApproved = false
            }
          }
        } else {
          this.$message.error(this.$t('l.no_main_tips'))
        }
      }
    }, 500)
  }
}
</script>

<style scoped>
.vault_top_nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#donghuaBox {
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: #52ccc5;
  border-radius: 100px;
  transition: 0.5s;
}
.vault_nav {
  position: relative;
  background-color: #000;
  width: 160px;
  max-width: 160px;
  height: 32px;
  line-height: 32px;
  border-radius: 20px;
  font-size: 14px;
  color: #888888;
  overflow: hidden;
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}
.valut_nav_item {
  flex: 1;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: 0.5s;
  text-align: center;
}
.valut_nav_item.active {
  color: #fff;
  border-radius: 16px;
}
li {
  list-style: none;
}
.my_nft_wrap {
  padding: 16px;
}
.my_balance {
  font-size: 14px;
  color: #888888;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 16px;
}
.copy_link {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: #000;
  padding: 0 8px;
  margin-top: 8px;
}
.link_title {
  font-size: 14px;
  color: #888888;
  white-space: nowrap;
  margin-right: 8px;
}
.link_wrap {
  width: 100%;
  height: 28px;
  background-color: #1f1f1f;
  border-radius: 14px;
  position: relative;
  padding: 0 32px 0 7px;
  overflow: hidden;
}
.link_val {
  width: 100%;
  max-width: 100%;
  display: inline-block;
  height: 100%;
  line-height: 28px;
  font-size: 12px;
  color: #888888;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.copy_icon {
  position: absolute;
  right: 7px;
  top: 2px;
}
.card_wrap {
  width: calc(100% + 8px);
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 0 -4px;
}
.card_item {
  width: calc(25% - 8px);
  margin: 0 4px 16px;
}
.img_wrap {
  width: 100%;
  position: relative;
}
.card_item_level {
  position: absolute;
  top: 16px;right: 16px;
  font-size: 14px;
  color: #C59F52;
  font-weight: bolder;
}
.card_item img {
  width: 100%;
  height: auto;
}
.card_info {
  display: flex;
  margin-top: 8px;
  padding: 0 8px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.card_info .edit_btn {
  width: calc(50% - 4px);
}
.card_info_item {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}
.card_title {
  font-size: 12px;
  color: #888888;
  white-space: nowrap;
  margin-right: 8px;
}
.card_val {
  font-size: 12px;
  color: #aaaaaa;
  font-weight: bolder;
}
.card_info:nth-child(4) .card_val {
  color: #c59f52;
}
.status .card_val {
  color: #52ccc5;
}
.sale_ipt {
  outline-style: none;
  border: 1px solid #c59f52;
  border-radius: 4px;
  width: 100%;
  height: 24px;
  color: #c59f52;
  background-color: rgba(255,255,255,.12);
  font-size: 12px;
  text-align: right;
}
.sale_ipt::placeholder {
  color: #c59f52;
}
.sale_btn {
  height: 32px;
  background: linear-gradient(#8ae8e4, #52ccc5);
}
.sale_btn_ghost {
  background: none;
  border-color: #52ccc5;
  color: #52ccc5;
  margin-right: 8px;
}
.btn_card_info {
  display: flex;
  justify-content: space-between;
}
.card_price {
  color: #c59f52;
}
.update_modal_wrap {
  padding: 8px 8px 16px;
  text-align: center;
}
.update_img {
  width: 100%;
  height: auto;
}
.num_text {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.num_text_title {
  color: #888888;
  font-size: 14px;
}
.num_text_val {
  color: #AAAAAA;
  font-weight: bolder;
  font-size: 14px;
}
.update_info {
  width: 100%;
  padding: 8px 12px;
  background-color: #141414;
  border-radius: 8px;
}
.balance {
  text-align: right;
  font-size: 12px;
  color: #CCCCCC;
  margin-bottom: 4px;
}
.cost {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cost_title {
  font-size: 14px;
  color: #888888;
}
.cost_val {
  font-size: 20px;
  font-weight: bolder;
  color: #52CCC5;
}
.update_btn_wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.modal_img_wrap .card_item_level {
  font-size: 20px;
  top: 32px;right: 30px;
}
@media (max-width: 768px) {
  .card_wrap {
    width: calc(100% + 8px);
    display: flex;
    flex-wrap: wrap;
    margin: 8px 0 0 -4px;
  }
  .card_item {
    width: calc(50% - 8px);
    margin: 0 4px 16px;
  }
}
</style>
<style>
  .update_modal .ant-modal-content{
    background-color: #000;
    border: 1px solid #C59F52;
    border-radius: 8px!important;
  }
  .update_modal .anticon {
    color: #C59F52;
    border: 1px solid #C59F52;
    background-color: #000;
  }
  .update_modal .ant-modal-close {
    top: -30px;
    right: -24px;
  }
  .update_modal .ant-modal-body {
    padding: 0;
  }
</style>