<template>
  <div class="vault_wrap">
    <Spin class="global_loading" tip="loading" :spinning="spinStatus" size="large">
      <div class="synthesis_wrap">
        <ul class="top_box">
          <li class="hc_img_item" v-for="(item,index) in normalChoosedArr" :key="index" @click="handleShowNormalModal">
            <img class="hc_img" :src="(!item || item.tokenURI == '') ? require('../assets/hc_default.png') : item.tokenURI" alt="">
            <!-- <p class="hc_img_num">{{(!item || item.nftKey == '') ? '' : item.nftKey}}</p> -->
          </li>
          <li class="hc_img_item" v-for="(item,index) in (5 - normalChoosedArr.length)" :key="'empty'+index" @click="handleShowNormalModal">
            <img class="hc_img" :src="require('../assets/hc_default.png')" alt="">
          </li>
          <li class="hc_center_img_item">
            <img class="hc_img" src="../assets/hc_center.png" alt="">
          </li>
        </ul>
        <div class="bottom_info">
          <div class="balance_wrap">
            <p class="balance">{{$t('l.balance')}}: {{accountBalance}} {{$t('l.token1')}}</p>
            <div class="cost_wrap">
              <span>{{$t('l.cost_ava')}}50U</span>
              <span>{{costFee}} {{$t('l.token1')}}</span>
            </div>
          </div>
          <div class="edit_btn full_btn hc_btn" v-if="hasApproved" @click="handleComposeConfirm">{{$t('l.hc')}}</div>
          <div class="edit_btn full_btn hc_btn" v-else @click="handleApproved">{{$t('l.approve')}}</div>
          <div class="to_my_nft"><span @click="handleJump">{{$t('l.my_nft')}}</span></div>
          <div class="hc_info">
            <p class="hc_title">{{$t('l.hc_title')}}</p>
            <p class="hc_desc" v-html="$t('l.hc_desc')"></p>
          </div>
        </div>
      </div>
      <Drawer class="choose_poke_modal choose_normal_modal" placement="bottom" :title="$t('l.choose_normal')" :visible="isShowChooseNormalModal" height="610px" @close="handleChooseNormalModalMCancel" :destroyOnClose="true" :zIndex="1000">
        <div class="choose_modal_wrap_root">
          <ul class="normal_modal_wrap">
            <li class="monster_item" @click="handleNormalChoose(item.id,index)" v-for="(item,index) in allMyPokeLists" :key="index" :class="normalChoosedKeyArr.indexOf(item.id) > -1 ? 'active' : ''">
              <div class="monster_itemBox" :style="'background-image:url('+item.tokenURI+')'"></div>
              <div class="monster_choose_circle" :class="normalChoosedKeyArr.indexOf(item.id) > -1 ? 'active' : ''">
                <div class="monster_choose_little_cicle"></div>
              </div>
              <div class="text_wrap">
                <div class="nft_info">
                  <p class="name">
                    <span class="num_val">{{item.nftKey}}</span>
                  </p>
                </div>
              </div>
              <!-- <span class="info_level">LV{{item.level}}</span> -->
            </li>
          </ul>
          <div class="choose_monster_btn_wrap">
            <Button class="edit_btn choose_monster_btn super_monster_choosed" @click="handleNormalChoosed">{{$t('l.confirm')}}</Button>
          </div>
        </div>
      </Drawer>
      <Modal></Modal>
      <Modal class="mint_modal" v-model="isShowComposeModal" :footer="null" :width="!$store.state.accounts.isMobile ? '375px' : '95%'" height="90%" @cancel="handleComposeModalClose" :centered="true" :destroyOnClose="true" :dialog-style="{ top: '30px' }">
        <div class="mint_modal_wrap">
          <div class="get_type_1">
            <img class="update_img" :src="targetComposePokeInfo.tokenURI" alt="">
            <div class="ball_num">{{targetComposePokeInfo.nftKey}}</div>
            <div class="ball_level">LV{{targetComposePokeInfo.level}}</div>
          </div>
          <div class="update_btn_wrap">
            <div class="edit_btn" @click="handleComposeModalClose">{{$t('l.confirm')}}</div>
          </div>
        </div>
      </Modal>
    </Spin>
  </div>
</template>

<script>
import { Drawer, Spin, Modal } from 'ant-design-vue'
import { mapGetters } from 'vuex'
import { ethers } from 'ethers';
import { batch, contract } from '@pooltogether/etherplex'
export default {
  components: {
    Drawer,
    Spin,
    Modal
  },
  computed: {
    ...mapGetters('accounts', ['getActiveAccount', 'getProviderEthers', 'getDemicals', 'getDataUpdateTime', 'getIsMainChainID']),
    ...mapGetters('contracts', ['getMaxUint256', 'getWFCAbi', 'getWFCAddress', 'getLordshipAbi', 'getLordshipAddress'])
  },
  data() {
    return {
      spinStatus: false,
      isShowChooseNormalModal: false,
      isShowComposeModal: false,
      contractWFC: null,
      hasApproved: false,
      normalChoosedKeyArr: [], //保存所有已选的NFT的id，drawer选择态使用
      normalChoosedArr: Array(5).fill(0), //保存所有已选的NFT的详情
      normalChoosedTypeArr: [], //保存所有已选的NFT的类型，筛选时使用
      allMyPokeIDLists: [],
      allMyPokeLists: [],
      accountBalance: 0,
      costFee: 0,
      targetComposePokeInfo: {}, //合成的poke详情
    }
  },
  methods: {
    handleJump() {
      this.$router.push('/mynft')
    },
    handleChooseNormalModalMCancel() {
      this.isShowChooseNormalModal = false
    },
    async handleComposeModalClose() {
      this.normalChoosedKeyArr = []
      this.normalChoosedArr = []
      this.normalChoosedTypeArr = []
      this.isShowComposeModal = false
      await this.getMyNFTArrs()
    },
    handleShowNormalModal() {
      this.isShowChooseNormalModal = true
    },
    handleNormalChoose(id,index) {
      if (this.normalChoosedKeyArr.indexOf(id) > -1) {
        //取消选中
        let index = this.normalChoosedKeyArr.indexOf(id)
        this.normalChoosedKeyArr.splice(index, 1)
        this.normalChoosedArr.splice(index, 1)
        this.normalChoosedTypeArr.splice(index, 1)
      } else {
        //选中
        if (this.normalChoosedKeyArr.length >= 5) {
          this.$message.error(this.$t('l.choose_more_than'))
          return
        } else {
          if(this.normalChoosedTypeArr.indexOf(this.allMyPokeLists[index].type) > -1) {
            this.$message.error(this.$t('l.choosed_same'))
            return
          }
          this.normalChoosedKeyArr.push(id)
          this.normalChoosedArr.push(this.allMyPokeLists[index])
          this.normalChoosedTypeArr.push(this.allMyPokeLists[index].type)
        }
      }
    },
    handleNormalChoosed() {
      if (this.normalChoosedArr.length < 5) {
        this.$message.error(this.$t('l.choosed_not_enough_poke'))
        return
      }
      this.isShowChooseNormalModal = false
    },
    async handleComposeConfirm() {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          if (this.normalChoosedKeyArr.length !== 5) {
            this.$message.error(this.$t('l.choosed_not_enough_poke'))
            return
          }
          this.spinStatus = true
          try {
            this.targetComposePokeInfo = {}
            let signer = this.getProviderEthers.getSigner();
            this.contractLord = new ethers.Contract(this.getLordshipAddress, this.getLordshipAbi, signer);
            let tx = await this.contractLord.compose(this.normalChoosedKeyArr,{gasLimit: 1500000})
            await tx.wait()
            //fixme 获取compose之后的详情
            let receipt = await tx.wait();
            const iface = new ethers.utils.Interface(["event LordComposed(address indexed user, uint256 tokenId)"])
            let log = receipt.logs.filter(log => log.address == this.getLordshipAddress)
            let result = iface.parseLog(log[11])
            if(result.args.user.toLowerCase() == this.getActiveAccount) {
              let composedID = result.args.tokenId
              let nftInfo = await this.contractLord.getTokenTraits(composedID)
              this.targetComposePokeInfo = {
                nftID: composedID,
                nftKey: this.$formatKey(composedID),
                tokenURI: await this.contractLord.tokenURI(composedID),
                level: nftInfo.level,
                type: nftInfo.role
              }
              this.$message.success(this.$t('l.operate_ok'))
              await this.handleShowComposeModal()
            }
            this.spinStatus = false
          } catch (error) {
            console.log(error)
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleShowComposeModal() {
      this.isShowComposeModal = true
    },
    async getMyNFTArrs() {
      //获取我的全部pokemon
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          this.spinStatus = true
          this.allMyPokeIDLists = []
          this.allMyPokeLists = []
          this.filterPokeLists = []
          try {
            let signer = this.getProviderEthers.getSigner();
            let provider = new ethers.providers.Web3Provider(window.ethereum)
            this.contractLord = new ethers.Contract(this.getLordshipAddress, this.getLordshipAbi, signer);
            this.contractWFC = new ethers.Contract(this.getWFCAddress, this.getWFCAbi, signer);
            //查询页面上的余额和升级花费
            this.accountBalance = this.$formatDecimal(ethers.utils.formatUnits(await this.contractWFC.balanceOf(this.getActiveAccount), this.getDemicals), 0)
            //fixme
            this.costFee = this.$formatDecimal(ethers.utils.formatUnits(await this.contractLord.composeCostWfc(), this.getDemicals), 0)
            if(this.accountBalance / 1 < this.costFee / 1) {
              this.spinStatus = false
              this.$message.error(this.$t('l.not_enough_wfc'))
              return
            }
            //获取所有NFT
            this.allMyPokeIDLists = (await this.contractLord.getTokensByAddress(this.getActiveAccount)).map(val => parseInt(val))
            let contractLordInfoArr = []
            let contractLordTokenURIArr = []
            for (let k = 0; k < this.allMyPokeIDLists.length; k++) {
              contractLordInfoArr.push(contract('Lordship'+k,this.getLordshipAbi,this.getLordshipAddress).getTokenTraits(this.allMyPokeIDLists[k]))
              contractLordTokenURIArr.push(contract('Lordship'+k,this.getLordshipAbi,this.getLordshipAddress).tokenURI(this.allMyPokeIDLists[k]))
            }
            let resultsInfo = await batch(provider,...contractLordInfoArr)
            let resultsTokenURI = await batch(provider,...contractLordTokenURIArr)
            for(let i = 0;i < this.allMyPokeIDLists.length;i++) {
              let nftInfo = resultsInfo['Lordship'+i].getTokenTraits[0]
              if (nftInfo.isHero) {
                this.allMyPokeLists.push({
                  id: this.allMyPokeIDLists[i],
                  nftKey: this.$formatKey(this.allMyPokeIDLists[i]),
                  level: parseInt(nftInfo.level),
                  tokenURI: resultsTokenURI['Lordship'+i].tokenURI[0],
                  type: parseInt(nftInfo.role)
                })
              }
            }
            //按等级排序，并筛选出5个默认的
            this.allMyPokeLists = this.allMyPokeLists.sort((val1, val2) => val1.level/1 - val2.level/1)
            this.normalChoosedArr = []
            this.normalChoosedKeyArr = []
            for(let j = 0;j < this.allMyPokeLists.length;j++) {
              if((this.normalChoosedTypeArr.indexOf(this.allMyPokeLists[j].type) == -1) && this.normalChoosedArr.length < 5) {
                this.normalChoosedKeyArr.push(this.allMyPokeLists[j].id)
                this.normalChoosedArr.push(this.allMyPokeLists[j])
                this.normalChoosedTypeArr.push(this.allMyPokeLists[j].type)
              }
            }
            this.spinStatus = false
          } catch (error) {
            console.log(error)
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleApproved() {
      if (this.getIsMainChainID) {
        try {
          if (!this.getProviderEthers || !this.getActiveAccount) {
            this.$message.error(this.$t('l.error_tips_unconnect'))
            return
          }
          this.spinStatus = true
          let tx = await this.contractWFC.approve(this.getLordshipAddress, this.getMaxUint256)
          await tx.wait()
          this.hasApprovedFor = true
          this.spinStatus = false
          this.$message.success(this.$t('l.approve_ok'))
        } catch (error) {
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
  },
  mounted() {
    let inviteAddress = this.$route.query.address ? this.$route.query.address : ''
    if(inviteAddress) {
      this.$setCookie('inviteAddress',inviteAddress,30 * 24 * 60 * 60)
    }
    let timeInterval = setInterval(async () => {
      if (!this.getWFCAbi || !this.getWFCAddress || !this.getLordshipAbi || !this.getLordshipAddress) {
        this.$store.dispatch("contracts/storeWFCAbi");
        this.$store.dispatch("contracts/storeWFCAddress");
        this.$store.dispatch("contracts/storeLordshipAbi");
        this.$store.dispatch("contracts/storeLordshipAddress");
      } else {
        clearInterval(timeInterval)
        await this.getMyNFTArrs()
      }
    }, 500)
    this.getHasApprovedInterVal = setInterval(async () => {
      if (this.getWFCAbi && this.getWFCAddress) {
        if (this.getIsMainChainID) {
          if (this.getActiveAccount) {
            let signer = this.getProviderEthers.getSigner();
            this.contractWFC = new ethers.Contract(this.getWFCAddress, this.getWFCAbi, signer);
            //查询授权信息
            let approveAmount = await this.contractWFC.allowance(this.getActiveAccount, this.getLordshipAddress)
            if (approveAmount._hex && approveAmount._hex > 0) {
              this.hasApproved = true
              clearInterval(this.getHasApprovedInterVal)
            } else {
              this.hasApproved = false
            }
          }
        } else {
          this.$message.error(this.$t('l.no_main_tips'))
        }
      }
    }, 500)
  }
}
</script>

<style scoped>
  .top_box {
    width: 100%;
    height: 375px;
    background-image: url('../assets/hc_bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    list-style: none;
  }
  .hc_img_item {
    width: 67px;
    height: 88px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
  }
  .hc_img_item img,.hc_center_img_item img {
    width: 100%;
    height: 100%;
  }
  .hc_img_item:nth-child(1) {
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }
  .hc_img_item:nth-child(2) {
    top: 50%;
    left: calc(50% - 162px);
    transform: translateY(-70%);
  }
  .hc_img_item:nth-child(3) {
    bottom: 16px;
    left: calc(50% - 98px);
  }
  .hc_img_item:nth-child(4) {
    bottom: 16px;
    left: calc(50% + 36px);
  }
  .hc_img_item:nth-child(5) {
    top: 50%;
    left: calc(50% + 98px);
    transform: translateY(-70%);
  }
  .hc_center_img_item {
    position: absolute;
    z-index: 2;
    width: 112px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .bottom_info {
    width: 100%;
    padding: 24px;
    background-color: #101719;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .balance_wrap {
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
    background-color: #1f2626;
    padding: 8px 12px;
  }
  .balance {
    font-size: 12px;
    color: #CCCCCC;
    text-align: right;
    margin-bottom: 4px;
  }
  .cost_wrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .cost_wrap span:nth-child(1) {
    font-size: 14px;
    color: #888888;
  }
  .cost_wrap span:nth-child(2) {
    font-size: 20px;
    color: #C59F52;
    font-weight: bolder;
  }
  .hc_btn {
    background: linear-gradient(#8AE8E4,#52CCC5);
    margin-top: 16px;
    max-width: 600px;
  }
  .to_my_nft {
    width: 100%;
    margin: 16px 0;
    font-size: 16px;
    color: #52CCC5;
    text-align: center;
  }
  .to_my_nft span {
    cursor: pointer;
  }
  .hc_info {
    width: 100%;
    max-width: 600px;
    padding: 24px 16px;
    background-color: #000;
    border: 1px solid #52CCC5;
    border-radius: 8px;
  }
  .hc_title {
    font-size: 20px;
    color: #C59F52;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 16px;
  }
  .hc_desc {
    font-size: 14px;
    color: #888888;
  }
  .choose_modal_wrap_root {
  width: 100%;
  overflow: hidden;
}
.choose_normal_modal .choose_modal_wrap_root {
  height: 100%;
}
.monster_wrap {
  width: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  list-style: none;
}
.normal_modal_wrap {
  max-height: 456px;
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
  justify-content: center;
}
.monster_item {
  width: 120px;
  min-width: 120px;
  border-radius: 8px;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
  list-style: none;
}
.normal_modal_wrap .monster_item {
  width: calc(33.3% - 8px);
  min-width: 88px;
  max-width: 136px;
  margin: 0 4px 4px;
}
.monster_itemBox {
  width: 100%;
  height: 196px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
}
.normal_modal_wrap .monster_itemBox {
  width: 100%;
  background-size: contain;
}
.monster_item .text_wrap {
  padding-top: 8px;
}
.nft_info .name {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 12px;
}
.normal_modal_wrap .nft_info .name {
  bottom: 18px;
}
.monster_item .text_wrap .num_val {
  font-family: aliheavy;
  font-size: 12px;
  color: #ffcb05;
  -webkit-text-stroke: 1px #316ab2;
}
.info_level {
  position: absolute;
  font-family: alibold;
  font-size: 14px;
  color: #c5f5f4;
  -webkit-text-stroke: 1px #5c7181;
  text-shadow: 0 2px 4px #000000;
  top: 8px;
  right: 12px;
}
.normal_modal_wrap .info_level {
  top: 20px;
  right: 12px;
}
.monster_choose_circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #ffe70c;
  padding: 4px;
  position: absolute;
  top: 12px;
  left: 12px;
  box-shadow: 0 1px 1px #000000;
}
.normal_modal_wrap .monster_choose_circle {
  top: 18px;
  left: 12px;
  border-radius: 4px;
}
.monster_choose_little_cicle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.monster_choose_circle.active {
  border-color: #ffe70c;
}
.normal_modal_wrap .monster_choose_circle.active {
  background-color: #ffe70c;
  border-color: #316ab2;
}
.monster_choose_circle.active .monster_choose_little_cicle {
  background-color: #ffe70c;
  box-shadow: 0 1px 1px #000000;
}
.normal_modal_wrap .monster_choose_circle.active .monster_choose_little_cicle {
  background-color: transparent;
  box-shadow: none;
}
.normal_modal_wrap
  .monster_choose_circle.active
  .monster_choose_little_cicle::after {
  content: '✓';
  color: #316ab2;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: -5px;
  left: 0;
}
.monster_choose_circle.disable {
  border-color: #888888;
  background-color: #dddddd;
}
.monster_choose_circle.disable .monster_choose_little_cicle {
  display: none;
}
.choose_monster_btn_wrap {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.choose_normal_modal .choose_monster_btn_wrap {
  margin-top: 10px;
}
.choose_monster_btn {
  width: 120px;
  max-width: 240px;
  height: 40px;
  border: 0;
  outline-style: none;
  border-radius: 12px;
  background: linear-gradient(#8AE8E4,#52CCC5);
}
.mint_modal_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.get_type_1 {
  width: 100%;
  max-width: 271px;
  display: flex;
  justify-content: center;
  position: relative;
}
.get_type_1 .update_img {
  width: 271px;
  height: auto;
}
.ball_num {
  position: absolute;
  color: #C59F52;
  font-size: 20px;
  bottom: 15px;
  font-weight: bolder;
}
.ball_level {
  position: absolute;
  font-size: 20px;
  color: #C59F52;
  top: 20px;right: 20px;
  font-weight: bolder;
}
.update_btn_wrap {
  width: 100%;
  max-width: 270px;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.update_btn_wrap .edit_btn{
   background: linear-gradient(#8AE8E4,#52CCC5);;
 }
 @media (max-width: 768px) {
  .normal_modal_wrap {
    justify-content: flex-start;
  }
  .normal_modal_wrap .monster_item {
    max-width: calc(33.3% - 8px)
  }
  .normal_modal_wrap .monster_choose_circle {
    top: 32px;
    left: 8px;
  }
 }
</style>

<style>
  .choose_poke_modal .ant-drawer-header {
    text-align: center;
    border-bottom: 0;
  }
  .choose_poke_modal .ant-drawer-title {
    font-size: 20px;
    color: #333333;
    font-weight: bolder;
  }
  .choose_poke_modal .anticon {
    color: #666666;
  }
  .choose_poke_modal .ant-drawer-content {
    /* background-color: #000; */
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  .choose_poke_modal .ant-drawer-body {
    padding: 16px 8px;
  }
  .choose_normal_modal .ant-drawer-body {
    height: calc(100% - 54px);
  }
  .mint_modal .ant-modal-content {
    background-color: transparent;
  }
  .mint_modal .ant-modal-close {
    display: none;
  }
</style>