<template>
  <div class="vault_wrap">
    <Spin class="global_loading" tip="loading" :spinning="spinStatus" size="large">
      <div class="market_wrap">
        <div class="vault_top_nav">
          <div class="copy_wrap">
            <input @keyup.enter="handleSearch" type="text" :placeholder="$t('l.enterNum')" class="ipt" v-model="searchVal">
            <img @click="handleSearch" src="../assets/search_icon.svg" alt="" class="search_icon">
          </div>
        </div>
        <ul class="nft_wrap">
          <li class="nft_item" v-for="(item,index) in pageNftLists" :key="index">
            <div class="nft_item_img_wrap">
              <img class="nft_img" :src="item.tokenURI" alt="">
              <div class="item_level">LV{{item.level}}</div>
            </div>
            <div class="sale_info">
              <p>
                <span class="info_title">{{$t('l.num')}}</span>
                <span class="info_val">{{item.nftKey}}</span>
              </p>
              <p>
                <span class="info_title">{{$t('l.price')}}</span>
                <span class="info_val">{{item.wantSalePrice}}</span>
              </p>
            </div>
            <div class="edit_btn full_btn buy_btn" v-if="hasApprovedFor" @click="handleBuy(item.nftID)">{{$t('l.buy')}}</div>
            <div class="edit_btn full_btn buy_btn" v-else @click="handleApproved">{{$t('l.approve')}}</div>
          </li>
        </ul>
        <div class="operate">
          <div class="jump_wrap">
            <input class="jump_ipt" v-model="targetJumpPage" /><span class="line">/</span><span class="total_page">{{pageTotal == 0 ? 1 : pageTotal}}</span>
            <Button class="jump_btn shadow_btn" @click="handleJumpToTargetPage">{{$t('l.go')}}</Button>
          </div>
          <div class="operate_btn_wrap">
            <Button class="paga_btn shadow_btn" type="primary" @click="handlePageChange('prev')">{{$t('l.prev')}}</Button>
            <Button class="paga_btn shadow_btn" type="primary" @click="handlePageChange('next')">{{$t('l.next')}}</Button>
          </div>
        </div>
      </div>
    </Spin>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { mapGetters } from 'vuex'
import { Spin } from 'ant-design-vue'
import Web3 from 'web3'
import { batch, contract } from '@pooltogether/etherplex'
export default {
  components: {
    Spin,
  },
  data() {
    return {
      spinStatus: false,
      currentIndex: 0,
      currentPage: 1,
      targetJumpPage: 1,
      pageTotal: 0,
      pageSize: 8,
      searchVal: undefined,
      hasApprovedFor: false,
      pageJumpType: 0, //翻页跳转的类型 0 默认 1 搜索
      contractLord: null,
      contractUSDT: null,
      nftSaleIDArr: [],
      nftLists: [], //所有在售的NFT的详细信息列表
      filterNftLists: [], //筛选后的NFT的详细信息列表
      pageNftLists: [], //分页后的当前页数据
    }
  },
  computed: {
    ...mapGetters('accounts',['getActiveAccount','getProviderEthers','getDemicals','getDataUpdateTime','getIsMainChainID']),
    ...mapGetters('contracts',['getMaxUint256','getUSDTAbi','getUSDTAddress','getLordshipAbi','getLordshipAddress'])
  },
  methods: {
    async getInitData() {
      if (this.getIsMainChainID) {
        this.spinStatus = true
        if (!this.getActiveAccount) {
          //未连接账号，只能查看，所有的按钮都是授权
          let rpc = this.$store.state.accounts.huobiNetWork[parseInt(this.$store.state.accounts.chainId ? this.$store.state.accounts.chainId : this.$store.state.accounts.mainChainID)]
          const web3 = new Web3(rpc)
          this.contractLord = new web3.eth.Contract(this.getLordshipAbi, this.getLordshipAddress);
          let nftSaleIDHexArr = []
          nftSaleIDHexArr = await this.contractLord.methods.getAllTokensOnSale().call()
          this.nftSaleIDArr = nftSaleIDHexArr.map(val => parseInt(val));
          await this.getNftLists(0)
        } else {
          //连接了账号，查询是否授权
          let signer = this.getProviderEthers.getSigner();
          this.contractLord = new ethers.Contract(this.getLordshipAddress, this.getLordshipAbi, signer);
          this.contractUSDT = new ethers.Contract(this.getUSDTAddress, this.getUSDTAbi, signer);
          let nftSaleIDHexArr = []
          nftSaleIDHexArr = await this.contractLord.getAllTokensOnSale()
          this.nftSaleIDArr = nftSaleIDHexArr.map(val => parseInt(val));
          let approveAmount = await this.contractUSDT.allowance(this.getActiveAccount, this.getLordshipAddress)
          if (approveAmount._hex && approveAmount._hex > 0) {
            this.hasApprovedFor = true
          } else {
            this.hasApprovedFor = false
          }
          await this.getNftLists(0)
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async getNftLists(type) {
      this.pageNftLists = []
      this.currentPage == 0 && (this.currentPage = 1)
      this.spinStatus = true
      if (type == 0) {
        //正常获取
        this.pageTotal = this.$pageCount(this.nftSaleIDArr.length, this.pageSize)
        this.currentPage > this.pageTotal ? (this.currentPage = this.pageTotal) : ''
        let currentPageIds = this.$pagination(this.currentPage, this.pageSize, this.nftSaleIDArr)
        this.pageNftLists = await this.getNFTListsByIDs(currentPageIds)
      } else if (type == 1) {
        //搜索获取
        if (this.nftSaleIDArr.length > 0) {
          let totalSearchIDs = []
          this.nftSaleIDArr.forEach(val => {
            if ((parseInt(val) + '') == (this.searchVal / 1 + '')) {
              totalSearchIDs.push(val)
            }
          })
          if (totalSearchIDs.length > 0) {
            this.pageTotal = this.$pageCount(totalSearchIDs.length, this.pageSize)
            this.currentPage > this.pageTotal ? (this.currentPage = this.pageTotal) : ''
            let currentPageIDs = this.$pagination(this.currentPage, this.pageSize, totalSearchIDs)
            this.pageNftLists = await this.getNFTListsByIDs(currentPageIDs)
          } else {
            this.$message.error(this.$t('l.no_own'))
          }
          this.spinStatus = false
        } else { this.$message.error(this.$t('l.no_nft')) }
      }
    },
    async getNFTListsByIDs(ids) {
      let provider = new ethers.providers.Web3Provider(window.ethereum)
      let nftLists = []
      let contractInfoArr = []
      let contractTokenURIArr = []
      let contractIsForSaleArr = []
      let contractSalePrice = []
      for (let i = 0; i < ids.length; i++) {
        contractInfoArr.push(contract('Lordship'+i,this.getLordshipAbi,this.getLordshipAddress).getTokenTraits(ids[i]))
        contractTokenURIArr.push(contract('Lordship'+i,this.getLordshipAbi,this.getLordshipAddress).tokenURI(ids[i]))
        contractIsForSaleArr.push(contract('Lordship'+i,this.getLordshipAbi,this.getLordshipAddress).forSale(ids[i]))
        contractSalePrice.push(contract('Lordship'+i,this.getLordshipAbi,this.getLordshipAddress).tokenPrice(ids[i]))
      }
      let resultInfoArr = await batch(provider,...contractInfoArr)
      let resultTokenURIArr = await batch(provider,...contractTokenURIArr)
      let resultIsForSaleArr = await batch(provider,...contractIsForSaleArr)
      let resultSalePriceArr = await batch(provider,...contractSalePrice)
      for(let j = 0;j < ids.length;j++) {
        let nftInfo = resultInfoArr['Lordship'+j].getTokenTraits[0]
        nftLists.push({
          nftID: ids[j],
          nftKey: this.$formatKey(ids[j]),
          level: parseInt(nftInfo.level),
          tokenURI: resultTokenURIArr['Lordship'+j].tokenURI[0],
          isForSale: resultIsForSaleArr['Lordship'+j].forSale[0],
          wantSalePrice: this.$formatDecimal(ethers.utils.formatUnits(resultSalePriceArr['Lordship'+j].tokenPrice[0],this.getDemicals),0),
        })
      }
      this.spinStatus = false
      return nftLists
    },
    async handleSearch() {
      if (this.searchVal !== '' && this.searchVal >= 0 && Number(this.searchVal / 1) >= 0) {
        this.currentPage = 1
        this.pageJumpType = 1
        this.getNftLists(this.pageJumpType)
      } else if (this.searchVal === '' || this.searchVal === undefined) {
        this.pageJumpType = 0
        await this.getInitData()
      } else { this.$message.error(this.$t('l.number_error')) }
    },
    async handleBuy(id) {
      if (this.getIsMainChainID) {
        if (this.getActiveAccount) {
          let owner = await this.contractLord.ownerOf(id)
          if (this.getActiveAccount == owner.toLowerCase()) {
            this.$message.error(this.$t('l.transSameAddress'))
            return
          }
          try {
            this.spinStatus = true
            let tx = await this.contractLord.buyLittleGui(id)
            await tx.wait()
            await this.getInitData()
            this.spinStatus = false
            this.$message.success(this.$t('l.operate_ok'))
          } catch (error) {
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        } else {
          this.$message.error(this.$t('l.error_tips_unconnect'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleApproved() {
      if (this.getIsMainChainID) {
        try {
          if (!this.getProviderEthers || !this.getActiveAccount) {
            this.$message.error(this.$t('l.error_tips_unconnect'))
            return
          }
          this.spinStatus = true
          let tx = await this.contractUSDT.approve(this.getLordshipAddress, this.getMaxUint256)
          await tx.wait()
          this.hasApprovedFor = true
          this.spinStatus = false
          this.$message.success(this.$t('l.approve_ok'))
        } catch (error) {
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      } else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleJumpToTargetPage() {
      if (!this.targetJumpPage || !Number(this.targetJumpPage)) {
        return
      }
      if (parseInt(this.targetJumpPage) <= 0) {
        this.currentPage = 1
        this.targetJumpPage = 1
        return
      } else if (parseInt(this.targetJumpPage) >= this.pageTotal) {
        this.targetJumpPage = this.pageTotal
        if(this.currentPage !== parseInt(this.targetJumpPage)) {
          this.currentPage = this.targetJumpPage
          await this.getNftLists(this.currentIndex)
        }else {
          this.currentPage = this.targetJumpPage
        }
        return
      } else {
        if(this.currentPage == parseInt(this.targetJumpPage)) {
          return
        }else {
          this.currentPage = this.targetJumpPage
        }
      }
      await this.getNftLists(this.currentIndex)
    },
    async handlePageChange(type) {
      if (type == 'prev') {
        if (this.currentPage > 1) {
          this.currentPage--
        } else {
          return
        }
      } else if (type == 'next') {
        if (this.currentPage < this.pageTotal) {
          this.currentPage++
        } else {
          return
        }
      }
      this.targetJumpPage = this.currentPage
      await this.getNftLists(this.currentIndex)
    },
  },
  mounted() {
    let inviteAddress = this.$route.query.address ? this.$route.query.address : ''
    if(inviteAddress) {
      this.$setCookie('inviteAddress',inviteAddress,30 * 24 * 60 * 60)
    }
    let timeInterval = setInterval(async () => {
      if (!this.getUSDTAbi || !this.getUSDTAddress || !this.getLordshipAbi || !this.getLordshipAddress) {
        this.$store.dispatch("contracts/storeUSDTAbi");
        this.$store.dispatch("contracts/storeUSDTAddress");
        this.$store.dispatch("contracts/storeLordshipAbi");
        this.$store.dispatch("contracts/storeLordshipAddress");
      } else {
        clearInterval(timeInterval)
        await this.getInitData()
      }
    }, 500)
  }
}
</script>

<style scoped>
.market_wrap {
  padding: 16px;
}
.nft_wrap {
  margin: 16px 0 0 -4px;
  width: calc((100% + 8px));
  display: flex;
  flex-wrap: wrap;
}
.nft_item {
  width: calc(25% - 8px);
  text-align: center;
  margin: 0 4px 16px 4px
}
.nft_item_img_wrap {
  width: 100%;
  position: relative;
}
.item_level {
  position: absolute;
  right: 0;top: 0;
  top: 16px;
  right: 16px;
  font-size: 14px;
  color: #C59F52;
  font-weight: bolder;
}
.nft_img {
  width: 100%;
  height: auto;
}
.sale_info {
  margin-top: 8px;
}
.sale_info p {
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
}
.info_title {
  font-size: 12px;
  color: #888888;
}
.info_val {
  font-size: 12px;
  font-weight: bolder;
  color: #AAAAAA;
}
.sale_info p:nth-child(2) .info_val {
  color: #C59F52;
  font-size: 14px;
}
.buy_btn {
  margin-top: 6px;
  height: 32px;
  background: linear-gradient(#8AE8E4,#52CCC5);
}
.vault_top_nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#donghuaBox {
  width: 50%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: #52ccc5;
  border-radius: 100px;
  transition: 0.5s;
}
.vault_nav {
  position: relative;
  background-color: #000;
  width: 160px;
  max-width: 160px;
  height: 32px;
  line-height: 32px;
  border-radius: 20px;
  font-size: 14px;
  color: #888888;
  overflow: hidden;
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
  padding: 0;
}
.valut_nav_item {
  flex: 1;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: 0.5s;
  text-align: center;
}
.valut_nav_item.active {
  color: #fff;
  border-radius: 16px;
}
li {
  list-style: none;
}
.copy_wrap {
  width: 100%;
  max-width: 375px;
  margin-left: auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.ipt {
  height: 40px;
  width: 100%;
  outline: none;
  border: 1px solid #7192b0;
  font-size: 14px;
  color: #666666;
  border-radius: 8px;
  padding: 0 16px;
  background-color: #101b27;
}

.ipt::placeholder {
  color: #8391a8;
}

.copy_wrap .search_icon {
  position: absolute;
  right: 12px;
  top: 8px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nft_item {
    width: calc(50% - 8px);
    margin: 0 4px 16px;
  }
}
</style>