<template>
  <div class="vault_wrap">
    <Spin class="global_loading" tip="loading" :spinning="spinStatus" size="large">
    <div class="vault_inner_wrap">
      <div class="pools-main">
        <div class="pools__item" v-for="(item,index) in poolsLists" :key="index">
            <Spin tips="loading" :spinning="item.isLoading" size="large">
              <div class="pools__box">
                <ul class="pools__rows">
                  <li class="pools__row-1">
                    <div class="pools__logo-name">
                      <img class="pools__coin-logo" :src="require('../assets/'+item.logo_url)">
                      <div class="pools__coin-name">{{item.tokenName}}</div>
                    </div>
                    <div class="pools__info">{{$t('l.reward')}} {{$t('l.token3')}}</div>
                  </li>
                  <li class="pools__row">
                    <div class="pools__labe-field">{{$t('l.y_lock')}}({{item.tokenName}})</div>
                    <div class="pools__label-value pools__label-value--black"><countTo :endVal='item.deposited/1' :duration='3000' :decimals="4"></countTo></div>
                  </li>
                  <li class="pools__row">
                    <div class="pools__labe-field">{{$t('l.t_lock')}}({{item.tokenName}})</div>
                    <div class="pools__label-value"><countTo :endVal='item.lock/1' :duration='3000' :decimals="4"></countTo></div>
                  </li>
                  <li class="pools__group-buttons">
                    <div class="pools__button-group">
                      <button class="g-button pools__dialog__option g-button-heco-theme  g-button--normal" @click="handleShowWithdrawModal(item.index)">{{$t('l.withdraw')}}</button>
                      <button v-if="!item.isApproved" class="g-button pools__dialog__option g-button-heco-theme " @click="handleApprovedFor(item.index)">{{$t('l.approve')}}</button>
                      <button v-else class="g-button pools__dialog__option g-button-heco-theme " @click="handleShowDepositModal(item.index)">{{$t('l.deposit')}}</button>
                    </div>
                  </li>
                </ul>
                <div class="pools__mao-logo__wrap">
                  <img src="" alt="" class="pools__mao-logo">
                </div>
              </div>
            </Spin>
        </div>
      </div>
      <Modal class="deposit_modal" v-model="isModalShow" :footer="null" :width="!$store.state.accounts.isMobile ? '600px' : '90%'" @cancel="handleMCancel" :centered="true">
        <div class="pools__dialog-inner pools__dialog-withdraw">
          <div class="pools__dialog__header">{{$t('l.withdraw')}}</div>
          <ul class="pools__rows">
            <li class="pools__row-1">
              <div class="pools__logo-name">
                <img class="pools__coin-logo" :src="currentPoolIndex > -1 ? require('../assets/'+poolsLists[currentPoolIndex].logo_url) : ''">
                <div class="pools__coin-name">{{currentPoolIndex > -1 ? poolsLists[currentPoolIndex].tokenName : ''}}</div>
              </div>
              <div class="pools__info">{{$t('l.reward')}} {{$t('l.token3')}}</div>
            </li>
            <li class="pools__income-field">
              <div class="pools__income-label">
                <p class="pools__income-title">{{$t('l.harvest')}}({{$t('l.token3')}})</p>
                <p class="pools__income-text"><countTo :endVal='incomeAmount/1' :duration='3000' :decimals="4"></countTo></p>
              </div>
              <!-- <div class="pools__income-button">
                <button @click="handleClaim" :disabled="incomeAmount/1 <= 0" class="g-button g-button-heco-theme ">{{$t('l.claim_sy')}}</button>
              </div> -->
            </li>
            <li class="pools__dialog__withdraw-field">
              <span>{{$t('l.y_lock')}}({{currentPoolIndex > -1 ? poolsLists[currentPoolIndex].tokenName : ''}})</span><span><countTo :endVal='(currentPoolIndex > -1 ? poolsLists[currentPoolIndex].deposited : 0)/1' :duration='3000' :decimals="4"></countTo></span>
            </li>
            <li class="pools__dialog__input">
              <input @input="input_num(1)" :placeholder="$t('l.iptPlace')" v-model="iptValue1">
              <button @click="handleWithAll" :disabled="(currentPoolIndex > -1 ? poolsLists[currentPoolIndex].deposited : 0) <= 0" class="g-button pools__dialog__deposit-all g-button-heco-theme  g-button--normal">{{$t('l.withdraw_all')}}</button>
            </li>
            <li>
              <button @click="handleWithDraw" :disabled="(currentPoolIndex > -1 ? poolsLists[currentPoolIndex].deposited : 0) <= 0" class="g-button pools__dialog__option g-button-heco-theme deposit_btn">{{$t('l.withdraw')}}</button>
            </li>
          </ul>
        </div>
      </Modal>
      <Modal class="deposit_modal" v-model="isModalShowSave" :footer="null" :width="!$store.state.accounts.isMobile ? '600px' : '90%'" @cancel="handleMCancel" :centered="true">
        <div class="pools__dialog-inner pools__dialog-deposite">
          <div class="pools__dialog__header">{{$t('l.deposit')}}</div>
          <ul class="pools__dialog__fields">
            <li class="pools__row-1">
              <div class="pools__logo-name">
                <img class="pools__coin-logo" :src="currentPoolIndex > -1 ? require('../assets/'+poolsLists[currentPoolIndex].logo_url) : ''">
                <div class="pools__coin-name">{{currentPoolIndex > -1 ? poolsLists[currentPoolIndex].tokenName : ''}}</div>
              </div>
              <div class="pools__info">{{$t('l.reward')}} {{$t('l.token3')}}</div>
            </li>
            <li class="pools__row">
              <div class="pools__labe-field">{{$t('l.y_lock')}} ({{currentPoolIndex > -1 ? poolsLists[currentPoolIndex].tokenName : ''}})</div>
              <div class="pools__label-value pools__label-value--black"><countTo :endVal='(currentPoolIndex > -1 ? poolsLists[currentPoolIndex].deposited : 0)/1' :duration='3000' :decimals="4"></countTo></div>
            </li>
            <li class="pools__row">
              <div class="pools__labe-field">{{$t('l.balance')}}({{currentPoolIndex > -1 ? poolsLists[currentPoolIndex].tokenName : ''}})</div>
              <div class="pools__label-value pools__label-value--black"><countTo :endVal='currentAccountBalance/1' :duration='3000' :decimals="4"></countTo></div>
            </li>
            <li class="pools__dialog__input">
              <input @input="input_num(2)" :placeholder="$t('l.iptPlace')" v-model="iptValue2">
              <button :disabled="currentAccountBalance <= 0" @click="handleDepositAll" class="g-button pools__dialog__deposit-all g-button-heco-theme  g-button--normal">{{$t('l.deposit_all')}}</button>
            </li>
            <li>
              <button :disabled="currentAccountBalance <= 0" @click="handleDepositConfirm" class="g-button pools__dialog__option g-button-heco-theme deposit_btn">{{$t('l.deposit')}}</button>
            </li>
          </ul>
        </div>
      </Modal>
    </div>
    </Spin>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { mapGetters } from 'vuex'
import { Spin,Modal } from 'ant-design-vue'
import countTo from 'vue-count-to';
import Web3 from 'web3'
export default {
  name: "Home",
  components: {
    Spin,
    countTo,
    Modal
  },
  data() {
    return {
      currentHref: window.location.origin+window.location.pathname,
      spinStatus: false,
      isModalShow: false,
      isModalShowSave: false,
      poolsLists: [],
      contractStake: null,
      contractDoy: null,
      iptValue: undefined,
      getDataInterVal: null,
      setStoreDataInterval: null,
      currentPoolID: -1,
      currentPoolIndex: -1,
      currentAccountBalance: 0,
      incomeAmount: 0,
      iptValue1: undefined,
      iptValue2: undefined,
    }
  },
  computed: {
    ...mapGetters('accounts',['getActiveAccount','getProviderEthers','getDemicals','getDataUpdateTime','getIsMainChainID']),
    ...mapGetters('contracts',['getMaxUint256','getDiamondAbi','getDiamondAddress', 'getDoyStakingAbi','getDoyStakingAddress'])
  },
  methods: {
    handleTapStart(e) {
      e.target.classList.add('tap')
    },
    handleTapEnd(e) {
      e.target.classList.remove('tap')
    },
    handleMCancel() {
      this.currentPoolIndex = -1;
      this.currentPoolID = -1
      this.currentAccountBalance = 0
      this.incomeAmount = 0
      this.iptValue1 = undefined
      this.iptValue2 = undefined
    },
    async handleApprovedFor(index) {
      if(this.getIsMainChainID) {
        try {
          if(!this.getProviderEthers || !this.getActiveAccount) {
            this.$message.error(this.$t('l.error_tips_unconnect'))
            return
          }
          if(this.poolsLists.length > 0 && index > -1) {
            this.spinStatus = true
            let signer = this.getProviderEthers.getSigner();
            let contractDoy = new ethers.Contract(this.getDiamondAddress, this.getDiamondAbi, signer);
            let tx = await contractDoy.approve(this.poolsLists[index].address,this.getMaxUint256)
            await tx.wait()
            this.poolsLists[index].isApproved = true
            this.spinStatus = false
            this.$message.success(this.$t('l.approve_ok'))
          }else {
            this.$message.error(this.$t('l.catch_err'))
          }
        } catch (error) {
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      }else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleShowWithdrawModal(index) {
      if(this.getActiveAccount) {
        this.spinStatus = true
        this.currentPoolIndex = index
        let signer = this.getProviderEthers.getSigner();
        this.contractStake = new ethers.Contract(this.poolsLists[index].address, this.getDoyStakingAbi, signer);
        let incomeHex = await this.contractStake.getReward(this.getActiveAccount)
        this.incomeAmount = this.$formatDecimal(ethers.utils.formatUnits(incomeHex,this.getDemicals),4)
        this.spinStatus = false
        this.isModalShow = true
      }else {
        this.$message.error(this.$t('l.error_tips_unconnect'))
      }
    },
    // async handleClaim() {
    //   if(this.getIsMainChainID) {
    //     try {
    //       if(!this.getProviderEthers || !this.getActiveAccount) {
    //           this.$message.error(this.$t('l.error_tips_unconnect'))
    //           return
    //       }
    //       if(this.incomeAmount <= 0) {
    //           this.$message.error(this.$t('l.error_tips_claim'));
    //           return
    //       }
    //       if (!Number(this.incomeAmount)) return
    //       if(this.currentPoolIndex > -1) {
    //         this.spinStatus = true
    //         let signer = this.getProviderEthers.getSigner();
    //         let contractStake = new ethers.Contract(this.getStakeAddress,this.getStakeAbi,signer);
    //         let tx = await contractStake.claim(this.poolsLists[this.currentPoolIndex].poolid)
    //         await tx.wait()
    //         this.currentPerIndex1 = -1
    //         this.currentAccountBalance += this.incomeAmount/1
    //         this.incomeAmount = 0
    //         this.iptValue1 = undefined
    //         await this.handleGetPoolsItemData()
    //         this.spinStatus = false
    //         this.$message.success(this.$t('l.ok_tips_claim'))
    //       }
    //     } catch (error) {
    //       this.spinStatus = false
    //       this.$message.error(this.$t('l.catch_err'))
    //     }
    //   }else {
    //     this.$message.error(this.$t('l.no_main_tips'))
    //   }
    // },
    async handleWithAll() {
      if(this.getIsMainChainID) {
        if(this.poolsLists[this.currentPoolIndex].deposited > 0) {
          if(!this.getProviderEthers || !this.getActiveAccount) {
              this.$message.error(this.$t('l.error_tips_unconnect'))
              return
          }
          try {
            this.spinStatus = true
            this.iptValue1 = this.poolsLists[this.currentPoolIndex].deposited
            let signer = this.getProviderEthers.getSigner();
            this.contractStake = new ethers.Contract(this.poolsLists[this.currentPoolIndex].address,this.getDoyStakingAbi,signer);
            let tx = await this.contractStake.withdrawAll()
            await tx.wait()
            this.$message.success(this.$t('l.operate_ok'))
            this.iptValue1 = undefined
            this.incomeAmount = this.$formatDecimal(ethers.utils.formatUnits(await this.contractStake.getReward(this.getActiveAccount) ,this.getDemicals),4)
            await this.handleGetPoolsItemData()
            this.spinStatus = false
          } catch (error) {
            console.log(error)
            this.iptValue2 = undefined
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        }else {
          this.$message.error(this.$t('l.not_enough_deposit'))
        }
      }else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleWithDraw() {
      if(this.getIsMainChainID) {
        try {
          if(!this.getProviderEthers || !this.getActiveAccount) {
              this.$message.error(this.$t('l.error_tips_unconnect'))
              return
          }
          if(this.iptValue1 <= 0) {
              this.$message.error(this.$t('l.error_tips_num0'));
              return
          }
          if(this.iptValue1 / 1 > this.poolsLists[this.currentPoolIndex].deposited / 1) {
              this.$message.error(this.$t('l.more_than_deposit'));
              return
          }
          if (!Number(this.iptValue1)) return
          if(this.iptValue1/1 > this.poolsLists[this.currentPoolIndex].deposited/1) {
              this.$message.error(this.$t('l.more_than_deposit'));
              return
          }
          if(this.currentPoolIndex > -1) {
            this.spinStatus = true
            let signer = this.getProviderEthers.getSigner();
            this.contractStake = new ethers.Contract(this.getDoyStakingAddress,this.getDoyStakingAbi,signer);
            const num = "0x" + (this.iptValue1 * (10 ** this.getDemicals)).toString(16);
            let tx = await this.contractStake.withdraw(num)
            await tx.wait()
            this.iptValue1 = undefined
            this.incomeAmount = this.$formatDecimal(ethers.utils.formatUnits(await this.contractStake.getReward(this.getActiveAccount) ,this.getDemicals),4)
            await this.handleGetPoolsItemData()
            this.spinStatus = false
            this.$message.success(this.$t('l.operate_ok'))
          }
        } catch (error) {
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      }else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleShowDepositModal(index) {
      if(this.getActiveAccount) {
        this.spinStatus = true
        this.currentPoolIndex = index
        let signer = this.getProviderEthers.getSigner();
        let contractDoy = new ethers.Contract(this.getDiamondAddress, this.getDiamondAbi, signer);
        let balanceHex = await contractDoy.balanceOf(this.getActiveAccount)
        this.currentAccountBalance = this.$formatDecimal(ethers.utils.formatUnits(balanceHex,this.getDemicals),4)
        this.spinStatus = false
        this.isModalShowSave = true
      }else {
        this.$message.error(this.$t('l.error_tips_unconnect'))
      }
    },
    async handleDepositAll() {
      if(this.getIsMainChainID) {
        if(this.currentAccountBalance > 0) {
          if(!this.getProviderEthers || !this.getActiveAccount) {
              this.$message.error(this.$t('l.error_tips_unconnect'))
              return
          }
          try {
            this.spinStatus = true
            this.iptValue2 = this.currentAccountBalance
            let signer = this.getProviderEthers.getSigner();
            this.contractStake = new ethers.Contract(this.poolsLists[this.currentPoolIndex].address,this.getDoyStakingAbi,signer);
            this.contractDoy = new ethers.Contract(this.getDiamondAddress,this.getDiamondAbi,signer);
            let tx = await this.contractStake.depositAll()
            await tx.wait()
            this.$message.success(this.$t('l.operate_ok'))
            this.iptValue2 = undefined
            this.currentAccountBalance = this.$formatDecimal(ethers.utils.formatUnits(await this.contractDoy.balanceOf(this.getActiveAccount),this.getDemicals),4)
            await this.handleGetPoolsItemData()
            this.spinStatus = false
          } catch (error) {
            console.log(error)
            this.iptValue2 = undefined
            this.spinStatus = false
            this.$message.error(this.$t('l.catch_err'))
          }
        }else {
          this.$message.error(this.$t('l.not_enough_balance'))
        }
      }else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async handleDepositConfirm() {
      if(this.getIsMainChainID) {
        try {
          if(!this.getProviderEthers || !this.getActiveAccount) {
              this.$message.error(this.$t('l.error_tips_unconnect'))
              return
          }
          if(this.iptValue2 <= 0) {
              this.$message.error(this.$t('l.error_tips_num0'));
              return
          }
          if (!Number(this.iptValue2)) return
          if(this.currentPoolIndex > -1) {
            this.spinStatus = true
            let signer = this.getProviderEthers.getSigner();
            this.contractStake = new ethers.Contract(this.poolsLists[this.currentPoolIndex].address,this.getDoyStakingAbi,signer);
            this.contractDoy = new ethers.Contract(this.getDiamondAddress,this.getDiamondAbi,signer);
            const num = "0x" + (this.iptValue2 * (10 ** this.getDemicals)).toString(16);
            let tx = await this.contractStake.deposit(num)
            await tx.wait()
            this.$message.success(this.$t('l.operate_ok'))
            this.iptValue2 = undefined
            this.currentAccountBalance = this.$formatDecimal(ethers.utils.formatUnits(await this.contractDoy.balanceOf(this.getActiveAccount),this.getDemicals),4)
            await this.handleGetPoolsItemData()
            this.spinStatus = false
          }
        } catch (error) {
          console.log(error)
          this.spinStatus = false
          this.$message.error(this.$t('l.catch_err'))
        }
      }else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    //此函数处理所有的数据，两个分支，只判断有没有已连接账号，没有账号统一按照rpc连接
    async handleGetPoolsItemData() {
      if(this.getIsMainChainID) {
        new Promise((resolve,reject) => {
          try {
            let promiseAllArr = []
            for(let i = 0;i < this.poolsLists.length;i++) {
              promiseAllArr[i] = new Promise((resolve) => {
                if(this.poolsLists[i].address) {
                  if(!this.getActiveAccount) {
                    //未连接具体账号，按照rpc方式，只获取基本数据(apy,lock)
                    let rpc = this.$store.state.accounts.huobiNetWork[parseInt(this.$store.state.accounts.chainId ? this.$store.state.accounts.chainId : this.$store.state.accounts.mainChainID)]
                    const web3 = new Web3(rpc)
                    let contractDoy = new web3.eth.Contract(this.getDiamondAbi,this.getDiamondAddress);
                    const promise1 = contractDoy.methods.balanceOf(this.poolsLists[i].address).call().then(totalDepositHex => {
                      this.poolsLists[i].lock = this.$formatDecimal(ethers.utils.formatUnits(totalDepositHex, this.getDemicals),4)
                    }).catch(err => {console.log(err)})
                    Promise.all([promise1]).then(() => {
                      this.poolsLists[i].isLoading = false
                      resolve('success')
                    }).catch(() => {
                      this.poolsLists[i].isLoading = false
                      resolve('success')
                    })
                  }else {
                    //已连接具体账号，除了apy和lock,还要查询授权信息和deposited数据
                    let signer = this.getProviderEthers.getSigner();
                    let contractStake = new ethers.Contract(this.poolsLists[i].address,this.getDoyStakingAbi,signer);
                    //apy
                    let contractDoy = new ethers.Contract(this.getDiamondAddress,this.getDiamondAbi,signer)
                    //lock
                    const promise1 = contractDoy.balanceOf(this.poolsLists[i].address).then(totalDepositHex => {
                      this.poolsLists[i].lock = this.$formatDecimal(ethers.utils.formatUnits(totalDepositHex, this.getDemicals),4)
                    }).catch(err => {console.log(err)})
                    //授权
                    const promise2 = contractDoy.allowance(this.getActiveAccount,this.poolsLists[i].address).then(approveAmount => {
                      if(approveAmount._hex && approveAmount._hex > 0) {
                        this.poolsLists[i].isApproved = true
                      }else {
                        this.poolsLists[i].isApproved = false
                      }
                    }).catch(() => {this.poolsLists[i].isApproved = false})
                    //查询当前账号deposited
                    const promise3 = contractStake._staking(this.getActiveAccount).then(depositedHex => {
                      this.poolsLists[i].deposited = this.$formatDecimal(ethers.utils.formatUnits(depositedHex, this.getDemicals),4)
                    }).catch(err => {console.log(err)})
                    Promise.all([promise1,promise2,promise3]).then(() => {
                      this.poolsLists[i].isLoading = false
                      resolve('success')
                    }).catch(() => {
                      this.poolsLists[i].isLoading = false
                      resolve('success')
                    })
                  }
                }else {
                  this.poolsLists[i].isLoading = false
                  resolve('success')
                }
              })
            }
            Promise.all(promiseAllArr).then(() => {
              resolve('success')
            }).catch(err => {
              reject(err)
            })
          } catch (error) {
            reject(error)
            this.$message.error(this.$t('l.catch_err'))
          }
        })
      }else {
        this.$message.error(this.$t('l.no_main_tips'))
      }
    },
    async getPoolsData() {
      clearInterval(this.getDataInterVal)
      this.poolsLists = []
      this.spinStatus = true
      this.$http.get('./static/pools.json',{},true).then(async (data) => {
        this.spinStatus = false
        this.poolsLists = (data && data.tokenpools) ? data.tokenpools : []
        await this.handleGetPoolsItemData()
        this.getDataInterVal = setInterval(async () => {
          await this.handleGetPoolsItemData()
        },this.getDataUpdateTime*10000)
      }).catch(err => {
        this.spinStatus = false
        console.log(err)
      })
    },
    input_num(index) {
      this['currentPerIndex' + index] = -1
      this['iptValue' + index] = this['iptValue' + index].replace(/[^\d.]/g, "")
      this['iptValue' + index] = this['iptValue' + index].replace(/\.{4,}/g, ".")
      this['iptValue' + index] = this['iptValue' + index].replace(/^\./g, "")
      this['iptValue' + index] = this['iptValue' + index].replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
      this['iptValue' + index] = this['iptValue' + index].replace(/^(-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3')
    },
  },
  created() {
    this.$store.dispatch("contracts/storeDiamondAbi");
    this.$store.dispatch("contracts/storeDiamondAddress");
    this.$store.dispatch("contracts/storeDoyStakingAbi");
    this.$store.dispatch("contracts/storeDoyStakingAddress");
    this.setStoreDataInterval = setInterval(async () => {
      if(!this.getDiamondAbi || !this.getDiamondAddress || !this.getDoyStakingAbi || !this.getDoyStakingAddress) {
        this.$store.dispatch("contracts/storeDiamondAbi");
        this.$store.dispatch("contracts/storeDiamondAddress");
        this.$store.dispatch("contracts/storeDoyStakingAbi");
        this.$store.dispatch("contracts/storeDoyStakingAddress");
      }else {
        clearInterval(this.setStoreDataInterval)
        await this.getPoolsData()
      }
    },500)
  },
  async mounted() {
  },
  destroyed() {
    clearInterval(this.getDataInterVal)
    clearInterval(this.setStoreDataInterval)
  }
}
</script>

<style scoped>
  .vault_top_nav {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .copy_wrap {
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
  }
  .copy_wrap .copy_text {
    white-space: nowrap;
  }
  .copy_wrap .link_text {
    width: 100%;
    height: 28px;
    line-height: 28px;
    border: 1px solid #52CCC5;
    border-radius: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 40px 0 12px;
    box-sizing: border-box;
    margin-left: auto;
    position: relative;
    margin-left: 12px;
  }
  .copy_wrap .link_text img {
    position: absolute;
    right: 12px;
    top: 4px;
    cursor: pointer;
  }
  .vault_nav {
    position: relative;
    background-color: #fff;
    width: 400px;
    max-width: 400px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    font-size: 14px;
    color: #666666;
    overflow: hidden;
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin: 0;padding: 0;
  }
  .valut_nav_item {
    flex: 1;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: 0.5s;
  }
  .valut_nav_item.active {
    color: #fff;
    /*background-color: #B98DCF;*/
    border-radius: 16px;
  }
  li {
    list-style: none;
  }
  .copy_wrap {
    width: 354px;
    height: 48px;
    background-color: #fff;
    border-radius: 8px;
  }
  .vault_inner_wrap {
    text-align: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px;
  }
  .pools-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pools__item {
    width: 100%;
    max-width: 600px;
    position: relative;
    background-color: #000;
    border-radius: 8px;
    border: 1px solid #52CCC5;
  }
  .pools__box {
    width: 100%;
    box-sizing: border-box;
    padding: 28px 28px;
    position: relative;
  }
  .pools__rows {
    position: relative;
    z-index: 1;
    list-style: none;
  }
  .pools__rows>li {
    margin-bottom: 16px;
  }
  .pools__rows>li:last-child {
    margin-bottom: 0;
  }
  .pools__row, .pools__row-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .pools__row {
    flex-wrap: wrap;
  }
  .pools__logo-name {
    display: flex;
    align-items: center;
    position: relative;
  }
  .pools__coin-logo {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  .logo_lp_2 {
    position: absolute;
    top: 0;left: 10%;
  }
  .pools__coin-name {
    margin-left: 12px;
    font-size: 24px;
    color: #cccccc;
    font-weight: bolder;
  }
  .name_lp_2 {
    margin-left: 40px;
  }
  .pools__info, .pools__labe-field {
    color: #888888;
    display: flex;
    align-items: center;
  }
  .pools__info {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: MicrosoftYaHei;
    margin-top: 4px;
  }
  .pools__apy {
    align-items: flex-end;
  }
  .pools__info, .pools__labe-field {
    color: #888888;
    display: flex;
    align-items: center;
  }
  .pools__labe-field, .ques__labe-field {
    color: #888888;
    display: flex;
    align-items: center;
  }
  .pools__apy-value {
    font-size: 24px;
    color: #00b595;
    font-weight: 700;
    font-family: DINPro-Black;
  }
  .pools__label-value {
    font-size: 14px;
    color: #cccccc;
    font-weight: bolder;
    margin-left: auto;
  } 
  .pools__label-value--black {
    color: #cccccc;
  }
  .pools__button-group {
    display: flex;
    justify-content: space-between;
  }
  .pools__button-group .g-button {
    width: 46%;
  }
  .g-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    outline: none;
    border: none;
    background-color: rgb(81, 204, 197);
    border-radius: 22px;
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    border-radius: 8px;
  }
  .g-button--normal {
    border: 1px solid #52ccc5;
    color: #52ccc5;
    background: none;
    border-radius: 8px;
  }
  .pools__mao-logo__wrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    overflow: hidden;
    border-radius: 16px;
  }
  .pools__mao-logo {
    -webkit-filter: blur(50px);
    filter: blur(50px);
    opacity: .3;
  }
  .pools__dialog-inner {
    box-sizing: border-box;
    width: 100%;
    min-width: 100%;
    border-radius: 16px;
    font-size: 14px;
  }
  .pools__dialog__header {
    font-size: 24px;
    color: #cccccc;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;
    position: relative;
  }
  .pools__income-field {
    background: #1f1f1f;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 32px;
  }
  .pools__income-button {
    width: 108px;
  }
  .pools__dialog__withdraw-field span:first-child {
    color: #888888;
  }
  .pools__dialog__withdraw-field span:last-child {
    font-size: 16px;
    color: #cccccc;
    float: right;
  }
  .pools__dialog__input input {
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    padding: 15px 100px 15px 15px;
    outline: none;
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 4px;
    border: 1px solid #C59F52;
    color: #666666;
  }
  .g-button:disabled {
    opacity: .5;
  }
  .pools__dialog__input {
    position: relative;
  }
  .pools__dialog__deposit-all {
    position: absolute;
    right: 20px;
    top: 10px;
    border-radius: 4px;
    border: none;
    width: auto;
    padding: 0 10px;
    height: 32px;
    font-size: 14px;
    color: #fff;
    background: linear-gradient(#E4CD8A,#C59F52);
  }
  .pools__income-text {
    font-family: DINPro-Bold;
    font-size: 16px;
    color: #888888;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 6px;
  }
  .pools__income-button .g-button {
    height: 36px;
  }
  .pools__dialog__fields li {
    margin-bottom: 22px;
  }
  .pools__income-title {
    color: #888888;
  }
  .deposit_btn {
    color: #fff;
    background: linear-gradient(#E4CD8A,#C59F52);
    border-radius: 4px;
  }
  @media (max-width: 768px) {
    .vault_nav {
      font-size: 12px;
      width: 94%;
      margin: 24px auto 16px;
    }
    .pools-main {
      width: 100%;
    }
    .pools__box {
      width: 100%;
      padding: 24px 16px;
    }
    .pools__rows>li {
      margin-bottom: 14px;
    }
    .pools__coin-name {
      font-size: 20px;
    }
    .pools__coin-logo {
      width: 36px;
      height: 36px;
    }
    .pools__info, .pools__labe-field {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: 400;
    }
    .pools__dialog-inner {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
    .pools__dialog__header {
      font-family: MicrosoftYaHei-Bold;
      font-size: 24px;
      color: #cccccc;
      line-height: 20px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    .pools__dialog__header__close {
      right: -26px;
      top: -23px;
      width: 48px;
      height: 48px;
      background-size: 16px 16px;
    }
    .pools__dialog__fields li {
      margin-bottom: 13px;
    }
    .pools__income-field {
      padding: 10px 10px;
    }
    .vault_top_nav {
      flex-direction: column;
    }
    .copy_wrap {
      order: -1;
      margin: 0;
    }
  }
</style>
<style>
  .global_loading.ant-spin-nested-loading > div > .ant-spin {
    width: 100%;height: 100vh;
    position: fixed!important;
    top: 0!important;
    bottom: 0!important;
    left: 0!important;
    right: 0!important;
    z-index: 99999!important;
    max-height: 10000px!important;
    background-color: rgba(255,255,255,.6);
  }
  .deposit_modal .ant-modal-content {
    border: 1px solid #c59f52;
    background-color: #000;
    border-radius: 8px !important;
  }
</style>