import addresses from "../../contracts/addresses.json";

import Diamond from '../../contracts/Diamond.json'
import DOP from '../../contracts/DOP.json'
import Lordship from '../../contracts/Lordship.json'
import PriceOracle from '../../contracts/PriceOracle.json'
import StakingNFT from '../../contracts/StakingNFT.json'
import WFC from '../../contracts/WFC.json'
import USDT from '../../contracts/USDT.json'
import PVP from '../../contracts/PVP.json'
import C2C from '../../contracts/C2C.json'
import DoyStaking from '../../contracts/DoyStaking.json'

const state = {
  unit256Max: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  DiamondAbi: null,
  DiamondAddress: null,
  DOPAbi: null,
  DOPAddress: null,
  LordshipAbi: null,
  LordshipAddress: null,
  PriceOracleAbi: null,
  PriceOracleAddress: null,
  StakingNFTAbi: null,
  StakingNFTAddress: null,
  WFCAbi: null,
  WFCAddress: null,
  USDTAbi: null,
  USDTAddress: null,
  PVPAbi: null,
  PVPAddress: null,
  C2CAbi: null,
  C2CAddress: null,
  DoyStakingAbi: null,
  DoyStakingAddress: null,
};

const getters = {
  getMaxUint256(state) {
    return state.unit256Max
  },
  getDiamondAbi(state) {
    return state.DiamondAbi;
  },
  getDiamondAddress(state) {
    return state.DiamondAddress;
  },
  getDOPAbi() {
    return state.DOPAbi;
  },
  getDOPAddress(state) {
    return state.DOPAddress
  },
  getLordshipAbi(state) {
    return state.LordshipAbi;
  },
  getLordshipAddress(state) {
    return state.LordshipAddress;
  },
  getPriceOracleAbi(state) {
    return state.PriceOracleAbi;
  },
  getPriceOracleAddress(state) {
    return state.PriceOracleAddress;
  },
  getStakingNFTAbi(state) {
    return state.StakingNFTAbi;
  },
  getStakingNFTAddress(state) {
    return state.StakingNFTAddress
  },
  getWFCAbi(state) {
    return state.WFCAbi;
  },
  getWFCAddress(state) {
    return state.WFCAddress
  },
  getUSDTAbi(state) {
    return state.USDTAbi;
  },
  getUSDTAddress(state) {
    return state.USDTAddress
  },
  getPVPAbi(state) {
    return state.PVPAbi;
  },
  getPVPAddress(state) {
    return state.PVPAddress
  },
  getC2CAbi(state) {
    return state.C2CAbi;
  },
  getC2CAddress(state) {
    return state.C2CAddress
  },
  getDoyStakingAbi(state) {
    return state.DoyStakingAbi;
  },
  getDoyStakingAddress(state) {
    return state.DoyStakingAddress
  },
};

const actions = {
  storeDiamondAbi({commit}) {
    commit("setDiamondAbi", Diamond.abi);
  },
  storeDiamondAddress({ commit, rootState }) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let DiamondAddress = addresses.Diamond[chainIdDec];
    commit("setDiamondAddress", DiamondAddress);
  },
  storeDOPAbi({commit}) {
    commit("setDOPAbi", DOP.abi);
  },
  storeDOPAddress({ commit, rootState }) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let DOPAddress = addresses.DOP[chainIdDec];
    commit("setDOPAddress", DOPAddress);
  },
  storeLordshipAbi({commit}) {
    commit("setLordshipAbi", Lordship.abi);
  },
  storeLordshipAddress({commit,rootState}) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let LordshipAddress = addresses.Lordship[chainIdDec];
    commit("setLordshipAddress", LordshipAddress);
  },
  storePriceOracleAbi({commit}) {
    commit("setPriceOracleAbi", PriceOracle.abi);
  },
  storePriceOracleAddress({commit,rootState}) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let PriceOracleAddress = addresses.PriceOracle[chainIdDec];
    commit("setPriceOracleAddress", PriceOracleAddress);
  },
  storeStakingNFTAbi({commit}) {
    commit("setStakingNFTAbi", StakingNFT.abi);
  },
  storeStakingNFTAddress({commit,rootState}) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let StakingNFTAddress = addresses.StakingNFT[chainIdDec];
    commit("setStakingNFTAddress", StakingNFTAddress);
  },
  storeWFCAbi({commit}) {
    commit("setWFCAbi", WFC.abi);
  },
  storeWFCAddress({commit,rootState}) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let WFCAddress = addresses.WFC[chainIdDec];
    commit("setWFCAddress", WFCAddress);
  },
  storeUSDTAbi({commit}) {
    commit("setUSDTAbi", USDT.abi);
  },
  storeUSDTAddress({commit,rootState}) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let USDTAddress = addresses.USDT[chainIdDec];
    commit("setUSDTAddress", USDTAddress);
  },
  storePVPAbi({commit}) {
    commit("setPVPAbi", PVP.abi);
  },
  storePVPAddress({commit,rootState}) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let PVPAddress = addresses.PVP[chainIdDec];
    commit("setPVPAddress", PVPAddress);
  },
  storeC2CAbi({commit}) {
    commit("setC2CAbi", C2C.abi);
  },
  storeC2CAddress({commit,rootState}) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let C2CAddress = addresses.C2C[chainIdDec];
    commit("setC2CAddress", C2CAddress);
  },
  storeDoyStakingAbi({commit}) {
    commit("setDoyStakingAbi", DoyStaking.abi);
  },
  storeDoyStakingAddress({commit,rootState}) {
    let chainIdDec = parseInt(rootState.accounts.chainId);
    let DoyStakingAddress = addresses.DoyStaking[chainIdDec];
    commit("setDoyStakingAddress", DoyStakingAddress);
  },
};

const mutations = {
  setDiamondAbi(state, abi) {
    state.DiamondAbi = abi;
  },
  setDiamondAddress(state, address) {
    state.DiamondAddress = address;
  },
  setDOPAbi(state, abi) {
    state.DOPAbi = abi;
  },
  setDOPAddress(state, address) {
    state.DOPAddress = address;
  },
  setLordshipAbi(state, abi) {
    state.LordshipAbi = abi;
  },
  setLordshipAddress(state,address) {
    state.LordshipAddress = address;
  },
  setPriceOracleAbi(state, abi) {
    state.PriceOracleAbi = abi;
  },
  setPriceOracleAddress(state,address) {
    state.PriceOracleAddress = address;
  },
  setStakingNFTAbi(state, abi) {
    state.StakingNFTAbi = abi;
  },
  setStakingNFTAddress(state,address) {
    state.StakingNFTAddress = address;
  },
  setWFCAbi(state, abi) {
    state.WFCAbi = abi;
  },
  setWFCAddress(state,address) {
    state.WFCAddress = address;
  },
  setUSDTAbi(state, abi) {
    state.USDTAbi = abi;
  },
  setUSDTAddress(state,address) {
    state.USDTAddress = address;
  },
  setPVPAbi(state, abi) {
    state.PVPAbi = abi;
  },
  setPVPAddress(state,address) {
    state.PVPAddress = address;
  },
  setC2CAbi(state, abi) {
    state.C2CAbi = abi;
  },
  setC2CAddress(state,address) {
    state.C2CAddress = address;
  },
  setDoyStakingAbi(state, abi) {
    state.DoyStakingAbi = abi;
  },
  setDoyStakingAddress(state,address) {
    state.DoyStakingAddress = address;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
