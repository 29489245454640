import Vue from "vue";
import Router from "vue-router";
import Nftmining from './pages/nftmining';
import myNFT from './pages/mynft';
import market from './pages/market';
import synthesis from './pages/synthesis';
import vault from './pages/vault';
//import battle from './pages/battle';
import doystore from './pages/doystore';
import doydeposit from './pages/doydeposit';

Vue.use(Router);

export default new Router({
  // Make sure the server can handle the history mode
  // If not, set it to hash (or delete the mode)
  // More info here: https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
  // mode: "history",
  routes: [{
      path: "/",
      name: "nftmining",
      component: Nftmining,
      meta: {
        index: 1
      }
    },
    {
      path: "/nftmining",
      name: "nftmining",
      component: Nftmining,
      meta: {
        index: 1
      }
    },
    {
      path: "/mynft",
      name: "mynft",
      component: myNFT,
      meta: {
        index: 2
      }
    },
    {
      path: "/market",
      name: "market",
      component: market,
      meta: {
        index: 3
      }
    },
    {
      path: "/synthesis",
      name: "synthesis",
      component: synthesis,
      meta: {
        index: 4
      }
    },
    {
      path: "/vault",
      name: "vault",
      component: vault,
      meta: {
        index: 5
      }
    },
    {
      path: "/battle",
      name: "battle",
      component: Nftmining,
      meta: {
        index: 6
      }
    },
    {
      path: "/doystore",
      name: "doystore",
      component: doystore,
      meta: {
        index: 7
      }
    },
    {
      path: "/doydeposit",
      name: "doydeposit",
      component: doydeposit,
      meta: {
        index: 8
      }
    },
    {
      path: "/*",
      redirect: {
        path: '/nftmining'
      },
    },
  ],
  linkActiveClass: "active"
});