var token1 = 'WFC';
var token2 = "DOP";
var token3 = "USDT";
var token4 = "DOY";

export const l = {
  token1,
  token2,
  token3,
  token4,
  h_n1 : "NFT Box",
  h_n2 : "MY NFT",
  h_n3 : "MARKET",
  h_n4 : "NFT Synthesis",
  h_n5 : "NFT Mining",
  h_n6 : "NFT PK",
  h_n7 : "DOY Market",
  h_n8:  "DOY Mining",

  cwallet: "CONNECT",
  left_box: "Remaining box",
  ge: "piece",
  balance: "Balance",
  value: "Value",
  buy: "Buy",
  confirm: "Confirm",
  my_nft: "My NFT >>",
  box_title: "Blind box description",
  box_desc: "Each blind box requires 50U worth of WFC+200U DOP extraction, and there are five different NFTs.",
  bag: "In Bag",
  sell: "On sale",
  diamond: "Diamond",
  invite_link: "Invite link",
  num: "Num",
  status: "Status",
  price: "Price(USDT)",
  sale: "Sale",
  unsale: "Cancel Sale",
  update: "Update",
  cancel: "Cancel",
  left_diamond: "Left Diamond",
  update_cost: "Diamond Cost",
  normal: "Normal NFT",
  super: "Super NFT",
  cost_ava: "Cost value",
  hc: "Synthesis Super NFT",
  hc_title: "Synthesis description",
  hc_desc: "Synthesis of 1 super NFT requires 5 different normal NFTs,<br /> synthesis of 1 super NFT requires 50U WFC",
  ava_token: "Harvest diamonds",
  rcl: "Daily output",
  y_lock: "Your Lock",
  t_lock: "Total Lock",
  deposit: "Deposit",
  withdraw: "Withdraw",
  mining_title: "Mining description",
  mining_desc: "Super card + DOP equal to the number of card levels for mining, the 'upgraded diamond DOY' produced by mining, the mining efficiency of different levels of cards is different, and the mining efficiency is equivalent to the overall cost of the card. When withdrawing The diamonds obtained by the project team are equivalent to a 10% handling fee (WFC), and those invited can get 5% of the mining revenue; upgrade diamonds can go to <a href='https://www.wfcdefi.com/swap/#/swap' target='_blank'>https://www.wfcdefi.com/swap/#/swap</a> to use USDT trading",
  left: "Remaining ",
  level: "Level",
  need: "Need",
  operate: "Operate",
  d_d_output: "Diamond Daily output",
  harvest: "Harvest",
  claim: "Claim",
  claim_sy: "Claim Harvest",
  deposit_dop: "Deposit DOP",
  draw: "Draw",
  battle: "Battle",
  create: "Create",
  battle_record: "Record",
  create_battle: "Create",
  battle_amount: "Amount(USDT)",
  fight: "Battle",
  evalute: "Evalute",
  cancel_battle: "Cancel",
  choose_nft: "Choose NFT",
  go: "Go",
  prev: "Prev",
  next: "Next",
  approve: "Approve",
  operate_ok: "Operate success",
  price_error: "Please enter right price！",
  enterNum: "Please enter number",
  choose_normal: "Choose Normal NFT",
  no_nft: "You do not have NFT,Please buy first!",
  no_own: "You do not have NFT of the number,Please buy first！",
  number_error: "Please enter right number！",
  transSameAddress: 'You already have the NFT!',
  choose_more_than: "You already choosed 5 NFT!",
  not_enough_poke: "Your NFT are less than 5 type!",
  choosed_not_enough_poke: "Please choose 5 NFT",
  choosed_same: "You already choose the NFT of the same type!",
  deposit_all_title: "NFT Total",
  deposit_all: "Deposit All",
  withdraw_all: "Withdraw All",
  error_tips_claim: "You have no claimable rewards",
  ok_tips_claim: "Claim Rewards success!",
  ok_tips_deposit: "Deposit success!",
  ok_tips_withdraw: "Withdrawal success!",
  ok_tips_claim_exit: "Exit: Claim & Unstake success",
  mint_done_tips: "All NFTs have been drawn!",
  not_enough_wfc: token1+"is not enough",
  f_title_1: "Num",
  f_title_2: "Level",
  f_title_3: "Diamond",
  f_title_4: "Operate",
  fight_info_title: "Battle Description",
  fight_info_desc: "When the hero PK, you can place a bet, "+token4+" pays, PK winner 10% to the platform, and another 5% to the referrer.",
  createbattle1: "Create Battle",
  add_nft_text: "Choose NFT",
  battle_amount_ipt: "Please enter battle amount(min 88 "+token4+")",
  tips_title: "Fee",
  battle_amount_error: "Please enter right battle amount",
  evaluated: "Evaluate",
  cancel_game: "Cancel",
  monbattle: "Start Battle",
  player1_monster: "Enemy NFT",
  player2_monster: "My NFT",
  start_battle: "Start",
  battle_amount_modal: "Battle Amount",
  not_enough_balance: "Balance not enough",
  record_title: "Battle Record",
  battle_modal_amount: "Amount("+token4+")",
  game_num: "Num",
  battle_result: "Battle Result",
  win_text: "Win",
  draw_text: "Draw",
  lose_text: "Lose",
  doy_buy: "Buy",
  doy_sale: "Sale",
  doy_buy_nav: "Buy Order",
  doy_sale_nav: "Sale Order",
  sale_doy_btn: "Sale DOY",
  buy_doy_btn: "Buy DOY",
  doy_nav_type1_market: "Sale Market",
  doy_nav_type1_my: "My Sale",
  doy_nav_type2_market: "Buy Market",
  doy_nav_type2_my: "My Buy",
  doy_title_1: "Amount",
  doy_title_2: "UnitPrice("+token3+")",
  doy_title_3: "Price("+token3+")",
  doy_title_4: "Operate",
  cancel_doy_sale: "Cancel",
  cancel_doy_buy: "Cancel",
  doy_price_title: token4+"'s latest transaction price",
  doy_sale_info_item_title1: "DOY Balance",
  doy_sale_info_item_title2: "Sale Amount",
  doy_buy_info_item_title2: "Buy Amount",
  doy_sale_info_item_title3: "Price",
  doy_ipt_place1: "Please enter amount",
  doy_ipt_place2: "please enter uint price",
  error_doy_input: "Please enter the correct number of diamonds and unit price",
  error_doy_input2: "Please enter the correct number of diamonds",
  insufficient_doy_input: "Insufficient diamond balance",
  insufficient_usdt_input: "Insufficient usdt balance",
  reward: "Harvest",
  iptPlace: "Enter Amount",
  error_tips_num0: "Please enter amount more than zero",
  not_enough_reward: "reward not enough",
  not_enough_deposit: "there are no "+token4+" to withdraw",
  more_than_deposit: "the enter amount more than your deposit amount",
  need_to_pay: "Cost",
  trans_all: "All",
  receive: "收到",

  footer1 : 'Audit',
  sjurl: 'https://wfcbsc.com/depfilm/audit.pdf',
  footer2 : 'Twitter',
  footer3 : 'Telegrame',

  langtype: "简体中文",
  approve_ok: "Approve Success,please retry!",
  error_tips_unconnect: "Please open MetaMask,connect your account!",
  notmainnet_title: "Warning",
  notmainnet_tips: "You're viewing data not from the main networks, please connected to the main network.",
  no_main_tips: "Network Error,Please make sure you connect to the BSC mainnet!",
  no_metamask_tips: "You have not installed the MetaMask wallet plugin!",
  catch_err: "Network Anomaly",
}